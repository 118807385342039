import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

// state management imports
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { O2FEffects } from "src/app/shared/store/effects/effects";
import { badgeTemplateReducer, brandToLocalReducer, campaignsReducer, categoryDataReducer, moderationCodes4filterReducer,
  productDataReducer, savedFiltersReducer, sourceReducer, themeDataReducer } from 'src/app/shared/store/reducers/reducer';

import { CanActivateGuard } from './core/services/can-activate.guard';
import { HttpLoaderFactory } from './shared/custom-translation.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MasterLayoutComponent } from './layout/master-layout/master-layout.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { MenubarComponent } from './shared/components/menubar/menubar.component';
import { AccessControlService } from './core/services/access-control.service';
import { ApplyThemeService } from './shared/services/apply-theme.service';
import { DataService } from './shared/services/data.service';
import { ProfileMenuComponent } from './shared/components/profile-menu/profile-menu.component';
import { EditProfileComponent } from './shared/components/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';
import { SharedCustomComponents } from './shared/components/shared-components';
import { SharedCustomDirective } from './shared/directives/shared-custom-directive';
import { ChangeThemeModule } from './shared/components/change-theme/change-theme.component';
import { AddNewClientComponent } from './shared/components/manage-client/manage-client';
import { SecurityService } from './core/services/security.service';

export function permissionGroupLoader(accessControlService: AccessControlService, securityService: SecurityService) {
  return () => {
    // check if external login
    if (location.href.split("?")[1]) {
      securityService.externalUserLoginStoreData(location.href);
    }    
    return accessControlService.getAccessPermissions();
  };
}
export function themeLoader(applyThemeService: ApplyThemeService) {
  return () => {
    return applyThemeService.loadThemeData();
  };
}
export function menuItemLoader(dataService: DataService) {
  return () => {
    return dataService.getMenubarData();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenubarComponent,
    ProfileMenuComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    MasterLayoutComponent,
    AddNewClientComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AngularMultiSelectModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({
      sources: sourceReducer, brand2Local: brandToLocalReducer,
      productData: productDataReducer, categoryData: categoryDataReducer,
      campaigns: campaignsReducer, savedFilters: savedFiltersReducer,
      moderationCodes4filter: moderationCodes4filterReducer,
      badgeTemplates: badgeTemplateReducer, themeData: themeDataReducer
    }),
    EffectsModule.forRoot([O2FEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 15 // Retains last 15 states
    }),

    AppRoutingModule,
    SharedCustomComponents,
    SharedCustomDirective,
    ChangeThemeModule
  ],
  exports: [SharedCustomComponents],
  providers: [
    CanActivateGuard,
    TranslateService,
    { provide: APP_INITIALIZER, useFactory: permissionGroupLoader, deps: [AccessControlService, SecurityService], multi: true },
    { provide: APP_INITIALIZER, useFactory: themeLoader, deps: [ApplyThemeService], multi: true },
    { provide: APP_INITIALIZER, useFactory: menuItemLoader, deps: [DataService], multi: true }  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
