export class MenuItemAccessModel {
    changeThemeVisible: boolean = false;
    reviewUploadScreenVisible: boolean = false;
    badgeUpdateScreenVisible: boolean = false;
    newsAndSupport: boolean = false;
    reviews: boolean = false;
    questionModeration: boolean = false;
    dashboard: boolean = false;
    productCoverage: boolean = false;
    pieConversion: boolean = false;
    globalComparison: boolean = false;
    reportScheduling: boolean = false;
    settings: boolean = false;
    voucherCampaigns: boolean = false;
    manageAccounts: boolean = false;
    automatedBadging: boolean = false;
    pieSetup: boolean = false;
    manageMarkets: boolean = false;
    manageRetailers: boolean = false;
    managePermissions: boolean = false;
    changeTheme: boolean = false;
    mailingStats: boolean = false;
    campaigningStats: boolean = false;
    voucher_campaigningStats: boolean = false;
    statistics: boolean = false;
    reportingStats: boolean = false;
    reviewUpload: boolean = false;
    activityMailing: boolean = false;
    platformStatus: boolean = false;
    isReviewUploaded: boolean = true;
    moderationVerificationStats: boolean = false;
    isMultiLanguageSetupAccess: boolean = false;
    isManageSubscription: boolean = false;
    badgeUpload: boolean = false;
    isBadgeUpdated: boolean = true;
    autoModeration: boolean = false;
    products_reviews: boolean = false;
    services_reviews: boolean = false;
    reviewManagement: boolean = false;
    moderationStatistics: boolean = false;

    products: boolean = false;
    products_overviews: boolean = false;
    services_overviews: boolean = false;
    productManagement: boolean = false;

}