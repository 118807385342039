<!-- <div *ngIf="visibleManageClientWindow" class="modalContainer overlayWrapper PF style-4"> -->

<div class="modal-header D-F JC_SB"
    [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
    <div>
        {{ initialState && initialState.popupTitle | translate}}
    </div>
    <div>
        <span class="icon icon-cancel cursorPointer" (click)="onCloseUpdateWindow()"></span>
    </div>
</div>

<div *ngIf="initialState" class="modal-body"
    [ngStyle]="{'background-color': 'white', color: applyThemeService.$textColor}">
    <form #addNewClientForm="ngForm" (ngSubmit)="onSubmitClientClick(addNewClientForm)" novalidate>
        <div class="row">
            <div class="col-md-4 filterlabel ">
                {{ "Company Name" | translate }} <sup class="redColor"> *</sup>:
            </div>
            <div class="col-md-8">
                <input class="W-100P boxShadow blackColor" type="text" name="companyName"
                    placeholder="{{'Enter'|translate}} {{'Company' | translate}} {{'Name' | translate}}" ngModel
                    #companyName="ngModel" [(ngModel)]="clientModel.companyName"
                    [class.disabled]="!initialState.isAddClientWindow" [disabled]="!initialState.isAddClientWindow"
                    (input)="allowOnlySpace(companyName)" />
                <ng-container *ngIf="companyName.invalid">
                    <div class="text-danger validation-errormsg">
                        {{ companyName.errors?.['error'] }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-4 filterlabel ">
                {{ "Sender Email" | translate }} <sup class="redColor"> *</sup>:
            </div>
            <div class="col-md-8">
                <input class="W-100P boxShadow blackColor" type="email" name="senderEmail" ngModel
                    #senderEmail="ngModel" [(ngModel)]="clientModel.senderEmail"
                    placeholder="{{'Enter'|translate}} {{ 'Sender' | translate }} {{'Email' | translate }}"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                <div class="text-danger validation-errormsg"
                    *ngIf="senderEmail.errors?.['touched'] || senderEmail.errors?.['dirty'] || senderEmail.errors?.['pattern'] || senderEmail.errors?.['invalid']">
                    <strong>{{"Error"|translate}}</strong>{{ "ERRORS.Please provide a valid email
                    address" |
                    translate }}
                </div>
            </div>
        </div>

        <div class="row mt-2" [class.clsbranddetails]="clientModel.brand.length > 0">
            <div class="col-md-4 filterlabel TA_L">
                {{ "Brand Details :" | translate }}
            </div>
            <div class="col-md-8">
                <button *ngIf="clientModel.brand.length == 0" type="button" class="cursorPointer ML-0"
                    [disabled]="!(clientModel.companyName && clientModel.senderEmail)"
                    [class.disabled]="!(clientModel.companyName && clientModel.senderEmail)"
                    [title]="(clientModel.companyName && clientModel.senderEmail) ? '': ('Please enter required fields first.' | translate)"
                    (click)="onAddNewBrand()">
                    <label class="selectaLabel">
                        <strong>+</strong>
                    </label>
                </button>
            </div>

            <ng-container *ngFor="let brand of clientModel.brand; let bIndex = index">
                <div class="D-F JC_SB gap-1 mb-2 PL-20">
                    <div>
                        <input class="col-md-12 boxShadow H-35 blackColor" type="text"
                            placeholder="{{'Brand' | translate}} {{'Name' | translate}} *" name="brandName_{{bIndex}}"
                            ngModel #brandName="ngModel" [(ngModel)]="brand.brandName"
                            [class.disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                            [disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                            (input)="checkUniqueInput(brandName, bIndex)" required />
                        <span *ngIf="brandName.invalid" class="text-danger brandvalidation-errormsg ML-30M">
                            {{ brandName.errors?.['error'] }}
                        </span>
                    </div>
                    <div>
                        <input class="col-md-12 boxShadow H-35 blackColor" type="text"
                            placeholder="{{'Brand' | translate}} {{'Short' | translate}} *" name="brandShort_{{bIndex}}"
                            ngModel #brandShort="ngModel" [(ngModel)]="brand.brandShort"
                            [class.disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                            [disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                            (input)="checkUniqueInput(brandShort, bIndex, 'brandShort')" required />
                        <span *ngIf="brandShort.invalid" class="text-danger brandvalidation-errormsg ML-30M">
                            {{ brandShort.errors?.['error'] }}
                        </span>
                    </div>
                    <div>
                        <input class="col-md-12 boxShadow H-35 blackColor"
                            placeholder="{{'Brand' | translate}} {{'Code' | translate}} *" type="text"
                            name="brandCode_{{bIndex}}" ngModel #brandCode="ngModel" [(ngModel)]="brand.brandCode"
                            validateNumber="4"
                            [class.disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                            [disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                            (input)="checkUniqueInput(brandCode, bIndex, 'brandCode')" required />
                        <span *ngIf="brandCode.invalid" class="text-danger brandvalidation-errormsg ML-30M">
                            {{ brandCode.errors?.['error'] }}
                        </span>
                    </div>
                    <div class="D-F gap-1">
                        <button type="button" class="cursorPointer"
                            [class.disabled]="(clientModel.brand.length-1 !== bIndex || addNewClientForm.invalid)"
                            [disabled]="(clientModel.brand.length-1 !== bIndex || addNewClientForm.invalid)"
                            (click)="onAddNewBrand()">
                            <label class="selectaLabel">
                                <strong>+</strong>
                            </label>
                        </button>
                        <button type="button" class="cursorPointer" (click)="onRemoveBrand(bIndex)"
                            [class.disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                            [disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount">
                            <label class="selectaLabel">
                                <strong>-</strong>
                            </label>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="row mt-2 TA_R">
            <div class="col-md-8"></div>
            <div class="col-md-4">
                <button *ngIf="accessControls.isCreate" class="btn btn-primary" [ngStyle]="{ 'background-color': applyThemeService.$themeColor, 'border-color': applyThemeService.$themeColor,
                            color: applyThemeService.$textColor}" [class.disabled]="addNewClientForm.invalid"
                    [disabled]="addNewClientForm.invalid">
                    {{"BUTTONS.Submit" |translate }}
                </button>
            </div>
        </div>
    </form>
</div>