export const environment = {
	production: false,
	// apiUrl:"http://dev-moderation-elx.msm-apps.de",
	// passKey:"9065ab5d-e80b-407d-8c5a-99887e38fd1b"
	envName: "dev",
	apiUrl: "https://dev.one2five.digital",
	reportingUrl: "https://dev.one2five.digital/reporting",
	premoderationUrl : 'https://dev.one2five.digital/premoderation',
	passKey: "13c19c73-1cd3-458f-a7ed-ed438e30c172",
  };
  