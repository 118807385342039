import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Daterangepicker } from 'ng2-daterangepicker';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatIconModule } from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MessagesComponent } from './messages/messages.component';
import { One2FiveLoaderComponent } from './app-loader/one2five.loader';
import { CustomTranslationModule } from '../custom-translation.module';
import { PassKeyComponent } from './pass-key/pass-key.component';
import { ReviewFilterComponent } from './review-filter/review-filter.component';
import { PartialStarRatingComponent } from './partial-star-rating/partial-star-rating.component';

@NgModule({
  declarations: [
    MessagesComponent,
    One2FiveLoaderComponent,
    PartialStarRatingComponent,
    PassKeyComponent,
    ReviewFilterComponent
  ],
  imports: [
    CommonModule, 
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule, 
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(0,129,0)', // ON color
      switchColor: '#fcfcfc',
      defaultBgColor: "rgb(255,0,0)", // OFF Color
      defaultBoColor : 'rgb(0,129,0)', // border color
      // checkedTextColor: "#7CFC00",
      // uncheckedTextColor: "red",
      // switchOffColor: "red"
      // checkedLabel: 'on',
      // uncheckedLabel: 'off'
    }),
    AngularMultiSelectModule,
    Daterangepicker,
    MatIconModule,
    CustomTranslationModule,
    MatTooltipModule
  ],
  exports: [
    UiSwitchModule,
    ColorPickerModule,
    MessagesComponent,
    One2FiveLoaderComponent,
    PartialStarRatingComponent,
    PassKeyComponent,
    ReviewFilterComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedCustomComponents { }
