<div class="modal-dialog modal-xl">
    <div class="modal-content modal-container">
        <div class="modal-header"
            [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
            <div class="reviewCountText">
                {{"MENUITEMS.Change Theme"| translate}}
            </div>
            <button class="transparentButton"
                [ngStyle]="{ 'background-color': applyThemeService.$secondaryColor, color: applyThemeService.$textColor }"
                (click)="onCloseChangeThemeWindow()">
                <span class="icon icon-cancel"></span>
            </button>
        </div>

        <div class="modal-body pt-2">
            <div class="row mb-2">
                <div class="col-md-6">
                    <div class="row AI_C">
                        <div class="col-md-3 TA_R">
                            {{"Welcome"| translate}} {{"Title"| translate}}
                        </div>
                        <div class="col-md-9">
                            <input type="text" autofocus [(ngModel)]="themeTemplateModel.welcomeTitle"
                                class="reportNameInput boxShadow" name="welcomeTitle"
                                placeholder='{{"Please"| translate}} {{"Enter"| translate}} {{"Welcome"| translate}} {{"Title"| translate}}' />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row AI_C">
                        <div class="col-md-3 TA_R">
                            {{"Welcome"| translate}} {{"Text"| translate}}
                        </div>
                        <div class="col-md-9">
                            <input type="text" [(ngModel)]="themeTemplateModel.welcomeText"
                                class="reportNameInput boxShadow" name="welcomeText"
                                placeholder='{{"Please"| translate}} {{"Enter"| translate}} {{"Welcome"| translate}} {{"Text"| translate}}' />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-6">
                    <div class="row AI_C">
                        <div class="col-md-3 TA_R">
                            {{"Copyright Label"| translate}}
                        </div>
                        <div class="col-md-9">
                            <input type="text" [(ngModel)]="themeTemplateModel.copyrightLabel"
                                class="reportNameInput boxShadow" name="copyrightLabel"
                                placeholder='{{"Please"| translate}} {{"Enter"| translate}} {{"Copyright Label"| translate}}' />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row AI_C">
                        <div class="col-md-3 TA_R">
                            {{"Document Title"| translate}}
                        </div>
                        <div class="col-md-9">
                            <input type="text" [(ngModel)]="themeTemplateModel.documentTitle"
                                class="reportNameInput boxShadow" name="copyrightLabel"
                                placeholder='{{"Please"| translate}} {{"Enter"| translate}} {{"Document"| translate}} {{"Title"| translate}}' />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2 height-30 pt-1">
                <div class="col-md-6 profileImageCol">
                    <div class="row AI_C">
                        <div class="col-md-3 TA_R">
                            {{"Large Logo"| translate}}
                        </div>
                        <div class="col-md-9">
                            <label *ngIf="!isLargeLogo" class="uploadImage">
                                <input type="file" (change)="onLargeFileChange($event)"
                                    placeholder='{{"Select Image"| translate}}'
                                    accept="image/x-png,image/jpeg,image/jpg" />
                            </label>
                            <span *ngIf="isLargeLogo">
                                <a [href]="largeLogoPath" target="_blank">{{"BUTTONS.View"| translate}} {{"large logo"|
                                    translate}}</a>
                                <span class="removeClass" (click)="onRemoveFile('isLargeLogo')">
                                    <span class="labelRemove">{{"remove"| translate}}</span></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 profileImageCol">
                    <div class="row AI_C">
                        <div class="col-md-3 TA_R">
                            {{"Small"| translate}} {{"Logo"| translate}}
                        </div>
                        <div class="col-md-9">
                            <label *ngIf="!isSmallLogo" class="uploadImage">
                                <input type="file" (change)="onSmallFileChange($event)"
                                    placeholder='{{"Select"| translate}} {{"Image"| translate}}'
                                    accept="image/x-png,image/jpeg,image/jpg" />
                            </label>
                            <span *ngIf="isSmallLogo">
                                <a [href]="smallLogoPath" target="_blank">{{"BUTTONS.View"| translate}} {{"small logo"|
                                    translate}}</a>
                                <span class="removeClass" (click)="onRemoveFile('isSmallLogo')">
                                    <span class="labelRemove">{{"remove"| translate}}</span></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2 height-30 pt-1">
                <div class="col-md-6 profileImageCol">
                    <div class="row AI_C">
                        <div class="col-md-3 TA_R">
                            {{"Favicon"| translate}}
                        </div>
                        <div class="col-md-9">
                            <label *ngIf="!isFavicon" class="uploadImage">
                                <input type="file" (change)="onFavIconImageChange($event)"
                                    placeholder='{{"Select"| translate}} {{"Image"| translate}}'
                                    accept="image/x-png,image/jpeg,image/jpg" />
                            </label>
                            <span *ngIf="isFavicon">
                                <a [href]="faviconPath" target="_blank">{{"BUTTONS.View"| translate}} {{"Favicon"|
                                    translate}}</a>
                                <span class="removeClass" (click)="onRemoveFile('isFavicon')">
                                    <span class="labelRemove">{{"remove"| translate}}</span></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 profileImageCol">
                    <div class="row AI_C">
                        <div class="col-md-4 TA_C">
                            {{"Privacy Statement"| translate}}
                        </div>
                        <div class="col-md-8">
                            <label *ngIf="!isPrivacyStatement" class="uploadImage">
                                <input type="file" (change)="onPrivacyStatementChange($event)"
                                    placeholder='{{"Select"| translate}} {{"HTML"| translate}} {{"Document"| translate}}'
                                    accept="text/html" />
                            </label>
                            <span *ngIf="isPrivacyStatement">
                                <a [href]="privacyStatementPath" target="_blank">{{"BUTTONS.View"| translate}}
                                    {{"Privacy Statement" | translate }}
                                </a>
                                <span class="removeClass" (click)="onRemoveFile('isPrivacyStatement')">
                                    <span class="labelRemove">{{"remove"| translate}}</span></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2 height-30 pt-1">
                <div class="col-md-6 profileImageCol">
                    <div class="row AI_C">
                        <div class="col-md-3 TA_R">
                            {{"About Us"| translate}}
                        </div>
                        <div class="col-md-9">
                            <label *ngIf="!isAboutUs" class="uploadImage">
                                <input type="file" (change)="onAboutUsFileChange($event)"
                                    placeholder='{{"Select HTML Document"| translate}}' accept="text/html" />
                            </label>
                            <span *ngIf="isAboutUs">
                                <a [href]="aboutUsPath" target="_blank">{{"BUTTONS.View"| translate}} {{"About Us"|
                                    translate}}</a>
                                <span class="removeClass" (click)="onRemoveFile('isAboutUs')">
                                    <span class="labelRemove">{{"remove"| translate}}</span></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row height-30 mt-3">
                <div class="col-md-3 colorOption">
                    <div class="row AI_C">
                        <div class="col-md-1"></div>
                        <div class="col-md-5 TA_R">
                            {{"Theme"| translate}} {{"Color"| translate}}
                        </div>
                        <div class="col-md-5">
                            <input class="circleColor" [style.background]="themeTemplateModel.themeColor"
                                [cpOKButton]="true" [cpOKButtonClass]="'btn btn-primary btn-xs'"
                                cpPosition="bottom-right" cpDialogDisplay="popup" cpOutputFormat="hex"
                                [(colorPicker)]="themeTemplateModel.themeColor"
                                (colorPickerSelect)="onClickThemeColorOkBtn($event)"
                                (cpSliderDragEnd)="onChangeThemeColor($event)" />
                        </div>
                    </div>
                </div>
                <div class="col-md-3 colorOption">
                    <div class="row AI_C">
                        <div class="col-md-1"></div>
                        <div class="col-md-5 TA_R">
                            {{"Text"| translate}} {{"Color"| translate}}
                        </div>
                        <div class="col-md-5">
                            <input class="circleColor" [style.background]="themeTemplateModel.textColor"
                                [cpOKButton]="true" [cpOKButtonClass]="'btn btn-primary btn-xs'"
                                cpPosition="bottom-right" cpDialogDisplay="popup" cpOutputFormat="hex"
                                [(colorPicker)]="themeTemplateModel.textColor"
                                (colorPickerSelect)="onClickTextColorOkBtn($event)"
                                (cpSliderDragEnd)="onChangeTextColor($event)" />
                        </div>
                    </div>
                </div>
                <div class="col-md-3 colorOption">
                    <div class="row AI_C">
                        <div class="col-md-6 TA_R">
                            {{"Secondary"| translate}} {{"Color"| translate}}
                        </div>
                        <div class="col-md-5">
                            <input class="circleColor W-50" [style.background]="themeTemplateModel.secondaryColor"
                                [cpOKButton]="true" cpPosition="bottom-left" cpDialogDisplay="popup"
                                [cpOKButtonClass]="'btn btn-primary btn-xs'" cpOutputFormat="hex"
                                [(colorPicker)]="themeTemplateModel.secondaryColor"
                                (colorPickerSelect)="onClickSecondaryColorOkBtn($event)"
                                (cpSliderDragEnd)="onChangeSecondaryColor($event)" />
                        </div>
                    </div>
                </div>
                <div class="col-md-3 colorOption">
                    <button class="btn btn-primary changeToDefaultBtn"
                        [ngStyle]="{ 'background-color': themeTemplateModel.themeColor, 'border-color': themeTemplateModel.themeColor, color: themeTemplateModel.textColor }"
                        (click)="onChangeToDefaultTheme()">
                        {{"Change"| translate}} {{"to"| translate}} {{"Default"| translate}}
                    </button>
                </div>
            </div>

            <div class="row mt-3">
                <div class="row AI_C">
                    <div class="col-md-1"></div>
                    <div class="col-md-10 shadow" [style.background]="themeTemplateModel.themeColor">
                        <span *ngIf="isSmallLogo" class="logo"
                            [style.backgroundImage]="'url(' + smallLogoPath + ')'"></span>
                        <span *ngIf="!isSmallLogo" class="logo">
                            <img class="img-responsive" [src]="logoPlaceholder" alt="Brand Logo" />
                        </span>
                        <span class="userProfile" [style.background]="themeTemplateModel.themeColor">
                            <img class="smallProfile" src="/assets/images/profilePicBig.png" alt="logo" />
                            <label class="userName" for="name" [style.color]="themeTemplateModel.textColor">{{"Hi"|
                                translate}},
                                John</label>
                        </span>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-2 drawerMenu" [style.background]="themeTemplateModel.themeColor">
                        <ul class="menuList">
                            <li [style.color]="themeTemplateModel.textColor"
                                [style.background]="themeTemplateModel.secondaryColor">
                                Lorem ipsum
                            </li>
                            <li [style.color]="themeTemplateModel.textColor">Lorem ipsum</li>
                            <li [style.color]="themeTemplateModel.textColor">Lorem ipsum</li>
                        </ul>
                    </div>
                    <div class="col-md-8 contentDiv">
                        <div class="row">
                            <div class="pageTitle">
                                <label for="page" class="labelTitle">{{"Ratings" | translate}} {{"and" | translate}}
                                    {{"Reviews" |
                                    translate}}</label>
                            </div>
                        </div>
                        <div class="title" [style.background]="themeTemplateModel.themeColor">
                            <label for="page" class="ML-10" [style.color]="themeTemplateModel.textColor">Lorem
                                ipsum</label>
                        </div>
                        <div class="filterDiv"></div>

                        <div class="title" [style.background]="themeTemplateModel.themeColor">
                            <label for="page" class="ML-10" [style.color]="themeTemplateModel.textColor">Lorem
                                ipsum</label>
                        </div>
                        <div class="filterDiv"></div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
            </div>
        </div>

        <div *ngIf="accessControls.isCreate" class="modal-footer">
            <button type="button" class="btn btn-primary w-25" [ngStyle]="{ 'background-color': applyThemeService.$themeColor,'border-color': applyThemeService.$themeColor,
                    color: applyThemeService.$textColor}" (click)="onSetNewThemeClick()">
                {{"BUTTONS.Submit" |translate }}
            </button>
        </div>
    </div>
</div>