import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { trigger, group, state, style, animate, transition, } from "@angular/animations";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { BsModalRef } from "ngx-bootstrap/modal";

import { getCurrentUserRole, One2FiveUtils } from "src/app/core/services/one2five-utils";
import { ApplyThemeService } from "../../services/apply-theme.service";
import { ConstantsService } from "../../services/constants.service";
import { AccessControlService } from "src/app/core/services/access-control.service";
import { CommonUtilService } from "src/app/core/services/common-util.service";
import { MessagesService } from "../messages/messages.service";
import { One2FiveHelperService } from "../../services/one2five-helper.service";

import { ReviewUploadComponent } from "src/app/modules/settings/components/review-upload/review-upload.component";
import { ThemeTemplateModel } from "../../models/theme.model";
import { ReviewUploadResponse } from "src/app/modules/review-management/models/review-upload.model";
import { MenuItemAccessModel } from "./menu-bar.model";
import { MenuBarData } from "../../models/referance.model";
import { MenuItemUrlsConstants, menuItemsUrl } from "./app-navigation";

@Component({
  selector: "app-menubar",
  templateUrl: "./menubar.component.html",
  styleUrls: ["./menubar.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          height: "420px",
          overflow: "visible",
        })
      ),
      state(
        "out",
        style({
          height: "0px",
          overflow: "hidden",
        })
      ),
      transition("in => out", [
        group([
          animate(
            "0ms ease-in-out",
            style({
              overflow: "hidden",
            })
          ),
        ]),
      ]),
      transition("out => in", [
        group([
          animate(
            "700ms ease-in-out",
            style({
              height: "420px",
              overflow: "visible",
            })
          ),
        ]),
      ]),
    ]),
    trigger("slideInOutStatistics", [
      state(
        "in",
        style({
          // height: "204px",
          height: "auto",
          overflow: "visible",
        })
      ),
      state(
        "out",
        style({
          height: "0px",
          overflow: "hidden",
        })
      ),
      transition("in => out", [
        group([
          animate(
            "0ms ease-in-out",
            style({
              overflow: "hidden",
            })
          ),
        ]),
      ]),
      transition("out => in", [
        group([
          animate(
            "700ms ease-in-out",
            style({
              height: "153px",
              overflow: "visible",
            })
          ),
        ]),
      ]),
    ]),
    trigger("slideInOutReviews", [
      state(
        "in",
        style({
          // height: "204px",
          height: "auto",
          overflow: "visible",
        })
      ),
      state(
        "out",
        style({
          height: "0px",
          overflow: "hidden",
        })
      ),
      transition("in => out", [
        group([
          animate(
            "0ms ease-in-out",
            style({
              overflow: "hidden",
            })
          ),
        ]),
      ]),
      transition("out => in", [
        group([
          animate(
            "700ms ease-in-out",
            style({
              // height: "153px",
              height: "auto",
              overflow: "visible",
            })
          ),
        ]),
      ]),
    ]),
  ]
})

export class MenubarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ReviewUploadComponent) reviewUploadComponent!: ReviewUploadComponent;
  themeTemplate = new ThemeTemplateModel;
  menubarData: MenuBarData[] = [];
  menuItemAccess = new MenuItemAccessModel;
  selectedItem: string = "";

  settingsAnimationState: string = "out";
  animationStatisticsState: string = "out";
  animationReviewsState: string = "out";
  isStatisticsOpen: boolean = false;
  isReviewsOpen: boolean = false;
  settingsOpen: boolean = false;
  isProductsOpen: boolean = false;
  animationProductsState: string = "out";

  reviewUploadDetails!: ReviewUploadResponse;
  badgeUpdateDetails!: ReviewUploadResponse;
  menuItemUrlsConstants = MenuItemUrlsConstants;

  subscriptions: Subscription[] = [];
  
  loadBadgeModal: boolean = false;
  loadReviewUploadModal: boolean = false;
  loadChangeThemeModal: boolean = false;
  badgeUpdateBSModalRef?: BsModalRef;
  reviewUploadBSModalRef?: BsModalRef;
  changeThemeBSModalRef?: BsModalRef;

  get isMSMUser(): boolean {
    return getCurrentUserRole().toUpperCase() == ConstantsService.MSM;
  }

  constructor(private router: Router,
    private viewRef: ViewContainerRef,
    public applyThemeService: ApplyThemeService,
    private accessControlService: AccessControlService,
    private one2FiveHelper: One2FiveHelperService,
    public commonUtilService: CommonUtilService) {
  }

  async ngOnInit() {
    this.subscriptions.push(this.applyThemeService.loadNewTheme$.subscribe((_theme: any) => {
      if (!_theme) return;
      this.themeTemplate = One2FiveUtils.objectClone(_theme);
    }));

    this.subscriptions.push(this.commonUtilService.loadMenuItems$.subscribe(_menu => {
      this.menubarData = _menu;
      this.setCurrentSelectedPage();
      this.detectRouteChanges();
    }));

    this.subscriptions.push(this.accessControlService.onLoadPermissionGroup$.subscribe(_permissions => {
      if (!_permissions) return;
      this.configureAccess();
    }));
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.one2FiveHelper.isCloseModalWindow$.subscribe(_isCloseModalWindow => {
      if (_isCloseModalWindow) {
        this.loadBadgeModal = false;
        this.badgeUpdateBSModalRef?.hide();
        this.loadReviewUploadModal = false;
        this.reviewUploadBSModalRef?.hide();
        this.loadChangeThemeModal = false;
        this.changeThemeBSModalRef?.hide();
      }
    }));
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach(_subscription => {
      if (_subscription) _subscription.unsubscribe();
    });
  }

  onMenuItemClick(): void {
    MessagesService.hideAlertMessages();
  }

  detectRouteChanges() {
    this.subscriptions.push(this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      MessagesService.hideAlertMessages();
      this.setCurrentSelectedPage();
    }));
  }

  setCurrentSelectedPage() {
    const selectedItem = this.router.url.slice(1, this.router.url.length);
    this.selectedItem = selectedItem.split("?")[0];

    this.checkIfSettingsOpen();
    this.checkIfStatisticsOpen();
    this.checkIfReviewsOpen();
    this.checkIfProductsOpen();
  }

  configureAccess() {
    this.menuItemAccess.newsAndSupport = this.accessControlService.configAccess(ConstantsService.NEWS_AND_SUPPORT).isRead;
    this.menuItemAccess.reviews = this.accessControlService.configAccess(ConstantsService.RATINGS_AND_REVIEWS).isRead;
    this.menuItemAccess.products_reviews = this.accessControlService.configAccess(ConstantsService.PRODUCTS_REVIEWS).isRead;
    this.menuItemAccess.services_reviews = this.accessControlService.configAccess(ConstantsService.SERVICES_REVIEWS).isRead;
    this.menuItemAccess.questionModeration = this.accessControlService.configAccess(ConstantsService.QUESTION_MODERATION).isRead;
    this.menuItemAccess.dashboard = this.accessControlService.configAccess(ConstantsService.DASHBOARD).isRead;
    this.menuItemAccess.productCoverage = this.accessControlService.configAccess(ConstantsService.PRODUCT_COVERAGE).isRead;
    this.menuItemAccess.pieConversion = this.accessControlService.configAccess(ConstantsService.PIE_CONVERSION).isRead;
    this.menuItemAccess.globalComparison = this.accessControlService.configAccess(ConstantsService.GLOBAL_COMPARISION).isRead;
    this.menuItemAccess.reportScheduling = this.accessControlService.configAccess(ConstantsService.REPORT_SCHEDULING).isRead;
    this.menuItemAccess.platformStatus = this.accessControlService.configAccess(ConstantsService.PLATFORM_STATUS).isRead;
    this.menuItemAccess.voucherCampaigns = this.accessControlService.configAccess(ConstantsService.VOUCHER_CAMPAIGN).isRead;
    this.menuItemAccess.manageAccounts = this.accessControlService.configAccess(ConstantsService.MANAGE_ACCOUNTS).isRead;
    this.menuItemAccess.automatedBadging = this.accessControlService.configAccess(ConstantsService.AUTOMATED_BADGING).isRead;
    this.menuItemAccess.pieSetup = this.accessControlService.configAccess(ConstantsService.PIE_SETUP).isRead;
    this.menuItemAccess.manageMarkets = this.accessControlService.configAccess(ConstantsService.MANAGE_MARKETS).isRead;
    this.menuItemAccess.manageRetailers = this.accessControlService.configAccess(ConstantsService.MANAGE_RETAILERS).isRead;
    this.menuItemAccess.reviewUpload = this.accessControlService.configAccess(ConstantsService.REVIEW_UPLOAD).isRead;
    this.menuItemAccess.managePermissions = this.accessControlService.configAccess(ConstantsService.MANAGE_PERMISSIONS).isRead;
    this.menuItemAccess.changeTheme = this.accessControlService.configAccess(ConstantsService.CHANGE_THEME).isRead;
    this.menuItemAccess.mailingStats = this.accessControlService.configAccess(ConstantsService.MAILING_STATISTICS).isRead;
    this.menuItemAccess.voucher_campaigningStats = this.accessControlService.configAccess(ConstantsService.VOUCHER_CAMPAIGNING_STATISTICS).isRead;
    this.menuItemAccess.campaigningStats = this.accessControlService.configAccess(ConstantsService.CAMPAIGNING_STATISTICS).isRead;
    this.menuItemAccess.reportingStats = this.accessControlService.configAccess(ConstantsService.USER_REPORT_STATISTICS).isRead;
    this.menuItemAccess.moderationStatistics = this.isMSMUser;

    this.menuItemAccess.statistics = this.accessControlService.configAccess(ConstantsService.STATISTICS).isRead;
    this.menuItemAccess.moderationVerificationStats = this.accessControlService.configAccess(ConstantsService.MODERATION_VERIFICATION_STATISTICS).isRead;
    this.menuItemAccess.isMultiLanguageSetupAccess = this.accessControlService.configAccess(ConstantsService.MULTI_LANGUAGE_SETUP).isRead;
    this.menuItemAccess.autoModeration = this.accessControlService.configAccess(ConstantsService.AUTO_MODERATION).isRead;
    this.menuItemAccess.badgeUpload = this.accessControlService.configAccess(ConstantsService.BADGE_UPDATE).isRead;
    this.menuItemAccess.products = this.accessControlService.configAccess(ConstantsService.PRODUCTS_OVERVIEW).isRead;
    this.menuItemAccess.products_overviews = this.accessControlService.configAccess(ConstantsService.PRODUCTS_OVERVIEW).isRead;
    this.menuItemAccess.services_overviews = this.accessControlService.configAccess(ConstantsService.SERVICE_OVERVIEW).isRead;

    this.menuItemAccess.settings = [
      this.menuItemAccess.changeTheme,
      this.menuItemAccess.managePermissions,
      this.menuItemAccess.manageMarkets,
      this.menuItemAccess.manageRetailers,
      this.menuItemAccess.pieSetup,
      this.menuItemAccess.automatedBadging,
      this.menuItemAccess.manageAccounts,
      this.menuItemAccess.voucherCampaigns,
      this.menuItemAccess.autoModeration,
      this.menuItemAccess.isMultiLanguageSetupAccess
    ].some((key) => key);

    this.menuItemAccess.activityMailing = [
      this.menuItemAccess.mailingStats,
      this.menuItemAccess.voucher_campaigningStats,
      this.menuItemAccess.reportingStats,
      this.menuItemAccess.moderationVerificationStats,
      this.menuItemAccess.campaigningStats,
      this.menuItemAccess.moderationStatistics
    ].some((key) => key);

    this.menuItemAccess.reviewManagement = [
      this.menuItemAccess.reviews,
      this.menuItemAccess.products_reviews,
      this.menuItemAccess.services_reviews
    ].some((key) => key);

    this.menuItemAccess.productManagement = [
      this.menuItemAccess.products,
      this.menuItemAccess.products_overviews,
      this.menuItemAccess.services_overviews
    ].some((key) => key);
  }

  onChangeTheme(themeTemplateModalRef: any) {
    this.loadChangeThemeModal = true;
    this.changeThemeBSModalRef = themeTemplateModalRef;
    themeTemplateModalRef.show();
  }
  onCloseChangeTheme() {
    this.changeThemeBSModalRef?.hide();
  }

  openReviewUploadWindow(reviewModalRef: any) {
    this.loadReviewUploadModal = true;
    this.reviewUploadBSModalRef = reviewModalRef;
    reviewModalRef.show();
  }
  openBadgeUploadWindow(badgeUpdateModalRef: any) {
    this.loadBadgeModal = true;
    this.badgeUpdateBSModalRef = badgeUpdateModalRef;
    badgeUpdateModalRef.show();
  }

  checkIfStatisticsOpen() {
    this.isStatisticsOpen = menuItemsUrl.statisticsPages.includes(this.selectedItem);
    this.animationStatisticsState = this.isStatisticsOpen ? "in" : "out";
  }
  checkIfSettingsOpen() {
    this.settingsOpen = menuItemsUrl.settingsPages.includes(this.selectedItem);
    this.settingsAnimationState = this.settingsOpen ? "in" : "out";
  }
  checkIfReviewsOpen() {
    this.isReviewsOpen = menuItemsUrl.reviewManagementPages.includes(this.selectedItem);
    this.animationReviewsState = this.isReviewsOpen ? "in" : "out";
  }
  checkIfProductsOpen() {
    this.isProductsOpen = menuItemsUrl.productManagementPages.includes(this.selectedItem);
    this.animationProductsState = this.isProductsOpen ? "in" : "out";
  }

  onActivityMailingClick() {
    this.isStatisticsOpen = !this.isStatisticsOpen;
    this.animationStatisticsState = this.isStatisticsOpen ? "in" : "out";
  }
  openSettingsMenu() {
    this.settingsOpen = !this.settingsOpen;
    this.settingsAnimationState = this.settingsOpen ? "in" : "out";
  }

  openReviews() {
    this.isReviewsOpen = !this.isReviewsOpen;
    this.animationReviewsState = this.isReviewsOpen ? "in" : "out";
    if (this.menuItemAccess.products_reviews && !this.menuItemAccess.services_reviews) {
      this.router.navigateByUrl('/review-management/products');
    } else if (!this.menuItemAccess.products_reviews && this.menuItemAccess.services_reviews) {
      this.router.navigateByUrl('/review-management/services');
    }
  }

  onOpenProducts() {
    this.isProductsOpen = !this.isProductsOpen;
    this.animationProductsState = this.isProductsOpen ? "in" : "out";
    if (this.menuItemAccess.products_overviews && !this.menuItemAccess.services_overviews) {
      this.router.navigateByUrl('/product-management/products');
    } else if (!this.menuItemAccess.products_overviews && this.menuItemAccess.services_overviews) {
      this.router.navigateByUrl('/product-management/services');
    }
  }

}
