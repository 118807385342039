<div class="filterContainerInside">
  <div class="reviewContainerHeader row AI_C"
    [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
    <div class="col-md-6 col-lg-6">
      <div class="row AI_C">
        <div class="col-lg-4 col-md-4">
          <span (click)="openCloseReviewFilter()"> {{ filterNameText | translate }} </span>
        </div>
        <div class="col-md-8 col-lg-8 pl-p2 pr-1">
          <ng-container *ngIf="isReviewFilterOpen && !isModeratorUserRole">
            <angular2-multiselect [data]="savedStates" [(ngModel)]="selectedSavedStates"
              [settings]="savedStatesDropdownSettings" (onSelect)="onChangeBookmark($event)"
              (onDeSelect)="onBookmarkDeSelect($event)">
            </angular2-multiselect>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 TA_R">
      <button type="button" [class.d-none]="!isReviewFilterOpen" class="btn btn-default saveStateColor"
        [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }"
        name="saveFilterButton" (click)="resetAllFilter(true)">
        <label class="labeltext cr-pointer" for="saveFilterButton" [ngStyle]="{ color: applyThemeService.$textColor }">
          {{ "BUTTONS.Clear All" | translate }}
        </label>
      </button>

      <button type="button" [class.d-none]="!isReviewFilterOpen" class="btn btn-default saveStateColor"
        [ngStyle]="{ 'background-color': applyThemeService.$themeColor,  color: applyThemeService.$textColor }"
        name="saveFilterButton" (click)="onSaveFilter(saveFilterTemplateRef)">
        <label class="labeltext" for="saveFilterButton" [ngStyle]="{ color: applyThemeService.$textColor }">{{
          "BUTTONS." +saveState | translate }}</label>
      </button>

      <button type="button" name="deleteFilterButton" [class.d-none]="!isReviewFilterOpen"
        class="btn btn-default saveStateColor"
        [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }"
        (click)="onDeleteFilter()" [disabled]="selectedSavedStates.length == 0"
        [class.disabled]="selectedSavedStates.length == 0">
        <label class="labeltext" for="deleteFilterButton" [class.disabled]="selectedSavedStates.length == 0"
          [ngStyle]="{ color: applyThemeService.$textColor }">{{ "BUTTONS.Delete View" | translate }}
        </label>
      </button>

      <span class="cr-pointer F_R" (click)="openCloseReviewFilter()"
       [ngStyle]="{ 'transform': isReviewFilterOpen ? 'rotate(90deg)' : 'rotate(0deg)'}">
        <span class="icon icon-right-arrow"></span>
      </span>
      
    </div>
  </div>

  <div [class.d-none]="!isReviewFilterOpen" #reviewFilterCollapsible>
    <div class="row mt-p5">
      <div *ngIf="filterAccess.BrandName" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Brand Name" | translate }}
            <mat-icon class="info" matTooltip="{{'Brand-Name-Tooltip' | translate }}" matTooltipPosition="after"
              aria-label="Brand-Name-Tooltip">info
            </mat-icon>:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [settings]="brandDropdownSettings" [data]="brandNameList"
              [(ngModel)]="brandSelectedItems" (onSelect)="onBrandNameSelect($event)"
              (onDeSelect)="onBrandNameDeSelect($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.BrandSite" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Brand Site" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [settings]="marketDropdownSettings" [data]="arrMarketList"
              [(ngModel)]="marketListSelectedItems" (ngModelChange)="onMarketExtraction($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.OpenSearch" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Open Search" | translate }}:
          </div>

          <div class="col-md-8 col-lg-8 filter">
            <div class="dateRangeInput">
              <input [(ngModel)]="filters.openSearch" type="text" class="" id="searchinput" #search
                (keyup.enter)="searchedText()" [placeholder]="'Search' | translate"
                style="all: unset; width: calc(100% - 47px);" />
              <span id="search-icon">
                <img *ngIf="search.value" class="deleteIcon" src="/assets/images/delete_visible.png"
                  (click)="clearSearch()" alt="delete" />
                <img *ngIf="!search.value" class="deleteIcon" src="/assets/images/delete_invisible.png" alt="-" />
                <img [class.disabled]="!search.value" class="searchIcon" src="/assets/images/search.png"
                  (click)="searchedText()" alt="search" />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.Category" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Category" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter" *ngIf="categoryDropdownSettings">
            <angular2-multiselect [data]="categoryList" [(ngModel)]="selectedCategory"
              [settings]="categoryDropdownSettings" (onSelect)="onCategorySelect($event)"
              (onDeSelect)="onCategoryDeSelect($event)" (onSelectAll)="onCategorySelectAll($event)"
              (onDeSelectAll)="onCategoryDeSelectAll($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.Product" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Product" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="productList" [(ngModel)]="selectedProduct"
              [settings]="productDropdownSettings" (onSelect)="onProductSelect($event)"
              (onDeSelect)="onProductDeSelect($event)" (onSelectAll)="onProductSelectAll($event)"
              (onDeSelectAll)="onProductDeSelectAll($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.Date" class="col-lg-6 col-md-6 col-sm-12" #datePickerContainerRef
        id="dateRangePickerParentRef">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">{{ "Date" | translate }}: </div>
          <div class="col-md-8 col-lg-8 filter">
            <input type="text" [ngClass]="filterAccess.Date ? 'dateRangeInput' : 'disableModule'" name="daterangeInput"
              daterangepicker autocomplete="off" [options]="datePickerOptions" [(ngModel)]="selectedDateRange"
              (selected)="onSelectedDate($event, dateRange)" (applyDaterangepicker)="onApplyCalender($event, dateRange)"
              (cancelDaterangepicker)="onCalendarCanceled($event)"
              placeholder="{{ 'Date Range' | translate }}"
              [disabled]="filterAccess.Date ? false : true" />
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.StarProduct" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Star Product" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="starProduct" [(ngModel)]="tempSelectedStarProduct"
              [settings]="starProductDropdownSettings" (onSelect)="onStarProductSelected($event)"
              (onDeSelect)="starProductRemoved($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.ProductStatus" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Product Status" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="productStatus" [(ngModel)]="selectedStarProduct"
              [settings]="productStatusDropdownSettings" (onSelect)="onProductStatusSelected($event)"
              (onDeSelect)="productStatusRemoved($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.ModerationCode" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Moderation Code" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="moderationCodes" [(ngModel)]="tempSelectedModerationCodes"
              [settings]="moderationCodesDropdownSettings" (onSelect)="onModerationCodeSelected($event)"
              (onDeSelect)="moderationCodeRemoved($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.ReviewSource" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Review Source" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="sourceList" [(ngModel)]="selectedSource" [settings]="sourceDropdownSettings"
              (onSelect)="onSourceSelect($event)" (onDeSelect)="onSourceDeSelect($event)"
              (onSelectAll)="onSourceSelectAll($event)" (onDeSelectAll)="onSourceDeSelectAll($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.ModerationStatus" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Moderation Status" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="reviewStatuses" [(ngModel)]="selectedReviewStatus"
              [settings]="moderationStatusDropdownSettings" (onSelect)="onReviewStatusSelected($event)"
              (onDeSelect)="reviewStatusRemoved($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.Campaign" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Campaign" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="campaigns" [(ngModel)]="campaignsSelectedItem" [settings]="campaignSettings"
              (onSelect)="onCampaignSelected($event)" (onDeSelect)="onCampaignItemDeSelect($event)"
              (onSelectAll)="onCampaignSelectAll($event)" (onDeSelectAll)="onCampaignDeSelectAll($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.ProductGrouping" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Product Grouping" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="productGroupTypeList" [(ngModel)]="tempSelectedProductGroupStatus"
              (onSelect)="onProductGroupTypeSelect($event)" (onDeSelect)="onProductGroupTypeDeSelect($event)"
              [settings]="productGroupTypeSetting">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.StarRating" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Star Rating" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="starRatings" [(ngModel)]="selectedRatings"
              [settings]="ratingsDropdownSettings" (onSelect)="onStarRatingChange()" (onDeSelect)="onStarRatingChange()"
              (onSelectAll)="onStarRatingChange()" (onDeSelectAll)="onStarRatingChange()">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.ReviewAnswers" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Answers to Reviews" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="reviewAnswers" [(ngModel)]="reviewAnswersChoice"
              [settings]="reviewAnswersDropdownSettings" (onSelect)="onReviewAnswerSelected($event)"
              (onDeSelect)="reviewAnswerRemoved($event)">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.Badges" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Badges" | translate }}:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <angular2-multiselect [data]="badgeTemplates" [(ngModel)]="selectedBadgeTemplate"
              [settings]="badgeDropdownSettings" (onSelect)="onBadgeTemplateSelect($event)"
              (onDeSelect)="onBadgeTemplateRemove()">
            </angular2-multiselect>
          </div>
        </div>
      </div>

      <div *ngIf="filterAccess.ReviewCount" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Review Count" | translate }}
            <mat-icon class="info" matTooltip="{{'ReviewCountTooltipLabel' | translate }}" matTooltipPosition="after"
              aria-label="ReviewCountTooltipLabel">info
            </mat-icon>:
          </div>
          <div class="col-md-3 col-lg-3">
            <angular2-multiselect [data]="reviewOperators" [(ngModel)]="reviewOperatorSelectedItem"
              [settings]="reviewCountDropdownSettings" (onSelect)="onReviewOperatorSelected($event)"
              (onDeSelect)="onReviewOperatorDeSelect($event)">
            </angular2-multiselect>
          </div>
          <div class="col-md-2 col-lg-2">
            <input type="number" [min]="0"
              matTooltip="{{ filters.reviewOperator == 'all' ? ('ReviewCountTooltipValue' | translate) : '' }}"
              class="dateRangeInput P-8" type="number" [(ngModel)]="filters.reviewCount"
              [disabled]="filters.reviewOperator == 'all'" [class.disabled]="filters.reviewOperator == 'all'" />
          </div>
          <div class="col-md-3 col-lg-3">
            <button class="btn btn-primary" [ngStyle]="{
            'background-color': applyThemeService.$themeColor,
            color: applyThemeService.$textColor }" [disabled]="filters.reviewOperator == 'all'"
              [class.disabled]="filters.reviewOperator == 'all'" (click)="onSearchReviewCount()">Search</button>
          </div>
        </div>
      </div>

      <div *ngIf="showReviewerField" class="col-lg-6 col-md-6 col-sm-12">
        <div class="row AI_C">
          <div class="col-md-4 col-lg-4 filterlabel">
            {{ "Reviewer Name" | translate }}
            <mat-icon class="info" matTooltip="{{'Reviewer-Name-tooltip' | translate }}" matTooltipPosition="after"
              aria-label="Reviewer-Name-tooltip message">info
            </mat-icon>:
          </div>
          <div class="col-md-8 col-lg-8 filter">
            <div [ngClass]="this.reviewerName ? 'dateRangeInput P-5' : 'disableModule P-5'">
              <input [ngClass]="this.reviewerName ? 'filledreviewer_input' : 'defaultreviewer_input'"
                [(ngModel)]="reviewerName" type="text" [disabled]="true" />
              <span *ngIf="this.reviewerName">
                <img *ngIf="this.reviewerName" class="closBtnReview" src="/assets/images/delete_visible.png"
                  (click)="onCloseReviewerData()" alt="delete" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="modal fade" bsModal #saveFilterTemplateRef="bs-modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header"
        [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
        <div></div>
        <div class="modalHeaderTitle">
          {{ "Save Filter" | translate }}
        </div>
        <button class="btn"
          [ngStyle]="{ 'background-color': applyThemeService.$secondaryColor, color: applyThemeService.$textColor }"
          (click)="closeSaveFilterWindow()">
          <span class="icon icon-cancel"></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row mt-4 AI_C">
          <div class="col-md-1 col-lg-1"></div>
          <div class="col-md-10 col-lg-10">
            <input class="form-control" type="text" [(ngModel)]="saveFilterByName"
              placeholder="{{'Enter new filter template name'|translate}}" />
          </div>
          <div class="col-md-1 col-lg-1"></div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" [disabled]="!saveFilterByName" [class.disabled]="!saveFilterByName"
          class="btn btn-primary"
          [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }"
          (click)="onSaveFilterState()">
          {{ "BUTTONS.Save" | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="closeSaveFilterWindow()">
          {{ "BUTTONS.Cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>