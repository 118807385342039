<div class="mainHeader fullWidth" [ngStyle]="{ 'background-color': applyThemeService.$themeColor }">
  <div class="logoDiv whiteColor inline-block" [ngStyle]="{
    'background-image': 'url(' + applyThemeService.$smallLogo + ')',
    '-webkit-filter': 'drop-shadow(4px 4px 5px rgba(0,0,0,0.5))'}">
  </div>
  <div class="pull-right VAlignChildrenForHeader">
    <div *ngIf="isCompanyMenuAccess" class="marketList">
      <angular2-multiselect #companyMultiSelect [data]="companyList" [ngModel]="companyListSelectedItems"
        [settings]="companyDropdownSettings" (onSelect)="onCompanySelect($event)"
        (onDeSelect)="onCompanyDeSelect($event)">
        <c-badge>
          <ng-template let-item="item">
            <label class="cursorPointer"
              style="margin: 0px; color: #000; max-width: 100%; font-weight: 400;">{{selectedCompanyName}}</label>
          </ng-template>
        </c-badge>
        <c-item>
          <ng-template let-item="item">
            <ng-container *ngIf="item.id == one2FiveConstant.newclient">
              <div class="cursorPointer FW-500 clsnew-client">
                {{ item.itemName }}
              </div>
            </ng-container>
            <ng-container *ngIf="item.id !== one2FiveConstant.newclient">
              <div class="cursorPointer D-F JC_SB MT-20M">
                <div class="W-15P">
                  <em *ngIf="selectedCompanyId == item.id" class="icon icon-right-mark F_L PT-4 blueColor"></em>
                  <em *ngIf="selectedCompanyId != item.id" class="F_L PT-4 blueColor"></em>
                </div>
                <div class="W-60P">
                  <label class="cursorPointer cls_ddlabel FW-500">
                    {{ item.itemName }}
                  </label>
                </div>
                <div class="W-15P">
                  <em *ngIf="clientAccessControls.isUpdate && loggedInUserCompany == item.itemName" title="Edit"
                    class="icon icon-edit F_R PT-4 clseditclientbtn" (click)="onOpenClientDetails(item)">
                  </em>
                  <em
                    *ngIf="!clientAccessControls.isUpdate || item.id == one2FiveConstant.newclient || loggedInUserCompany != item.itemName"></em>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </c-item>
      </angular2-multiselect>
    </div>
    &nbsp;
    <div *ngIf="isMultiLanguageSetupAccess && languages" class="W-70">
      <angular2-multiselect [data]="languages" [(ngModel)]="languageCodeSelected" [settings]="languageCodeSettings"
        (onSelect)="onLanguageSelection($event)" (onDeSelect)="onLanguageDeSelection($event)">
        <c-badge>
          <ng-template let-item="item">
            <label class="cursorPointer" style="margin: 0px; color: #000; max-width: 100%; font-weight: 400;">{{
              item.itemName }}</label>
          </ng-template>
        </c-badge>
        <c-item>
          <ng-template let-item="item">
            <label class="cls_ddlabel" [title]="item.languageName">{{ item.itemName }}</label>
            <img [src]="item.path" alt="Not Found" [title]="item.languageName" class="cls_dditem" />
          </ng-template>
        </c-item>
      </angular2-multiselect>
    </div>

    <div *ngIf="show" class="marketList">
      <angular2-multiselect [data]="marketList" [(ngModel)]="marketListSelectedItems"
        [settings]="marketDropdownSettings" (onSelect)="onMarketSelect($event)" (onDeSelect)="onMarketDeSelect($event)"
        (onSelectAll)="onMarketSelectAll($event)" (onDeSelectAll)="onMarketDeSelectAll($event)">
      </angular2-multiselect>
    </div>
    <span class="menuOptions whiteColor inline-block font-weight" (click)="clickedInside($event)" [ngStyle]="{
        'background-color': profileMenuClick ? applyThemeService.$secondaryColor : applyThemeService.$themeColor,
        color: applyThemeService.$textColor
      }">
      <span class="paddingDP"></span>
      <span class="smallProfile" [style.backgroundImage]="'url(' + smallProfilePic + ')'">
      </span>
      {{ "Hi" | translate }}, {{ userProfile ? userProfile.userNickname : '' }}
      <span class="paddingArrow" [ngStyle]="{
          transform: profileMenuClick == true ? 'rotate(90deg)' : 'rotate(0deg)'
        }">
        <span class="icon icon-right-arrow"></span>
      </span>
    </span>
  </div>
</div>

<app-profile-menu *ngIf="profileMenuClick" (editProfile)="onEditProfile()" (changePassword)="onChangePassword()"
  (changeTheme)="onChangeTheme()" (logout)="onLogOut()">
</app-profile-menu>

<app-edit-profile *ngIf="editProfileVisible" (destroy)="closeEditProfile()"></app-edit-profile>

<app-change-password *ngIf="changePasswordVisible" (success)="changePassShowMsg()" (destroy)="closeChangePassword()">
</app-change-password>

<div *ngIf="changeThemeVisible" class="changePassDiv">
  <app-change-theme (destroy)="closeChangeTheme()" (refresh)="refresh()"></app-change-theme>
</div>

<div *ngIf="changePassSuccess" class="alert alert-success successAlert" role="alert">
  {{ "Your" | translate }} {{ "password" | translate }} {{ "has" | translate }} {{ "changed" | translate }} {{
  "successfully" | translate }}!
</div>

<div *ngIf="showWindowForEmail" class="overlayWrapper PF style-4">
  <div class="editProfileDetailView">
    <div class="row containerHeader" [ngStyle]="{
        'background-color': applyThemeService.$themeColor,
        color: applyThemeService.$textColor
      }">
      <div class="col-md-offset-1 col-md-9 editProfileName">
        {{ "Update" | translate }} {{ "Email" | translate }} {{ "Address" | translate }}
      </div>
      <div class="col-md-offset-1 col-md-1">
        <span class="closeEditProfile pull-right" (click)="closeEmailView()">
          <span class="iconContainerSpan spanContainer closeBtnContainer">
            <span class="icon icon-cancel"></span>
          </span>
        </span>
      </div>
    </div>

    <div class="row rowMargin">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10 note">
            <strong>{{ "Note" | translate }}:</strong>
            {{ "Please enter your email address as it will be used for communications in emergency situations" |
            translate }}.
            <br />
            <br />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <input class="password" type="email" name=""
              placeholder='{{"Enter"| translate}} {{"Email"| translate}} {{"Address"| translate}}'
              [(ngModel)]="userProfile.emailId" />
          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <button type="button" class="btn btn-primary customBtn" [ngStyle]="{
                'background-color': applyThemeService.$themeColor,
                'border-color': applyThemeService.$themeColor,
                color: applyThemeService.$textColor
              }" (click)="onSubmitEmail()" [disabled]="commonUtilService.showLoader">
              {{ "BUTTONS.Submit" | translate }}
            </button>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="modal fade" bsModal #template="bs-modal" tabindex="-1" role="dialog">
  <!-- <div #componentRef></div> -->
  <div class="modal-dialog modal-lg">
  <app-manage-client (closeClientWindow)="onCloseModal()"></app-manage-client>
  </div>
</div>