import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { One2FiveUtils, isEmpty, isSpacePresent, isSpecialCharAndNumberFound } from 'src/app/core/services/one2five-utils';
import { One2FiveLoadingService } from '../app-loader/loader.service';
import { MessagesService } from '../messages/messages.service';
import { ApplyThemeService } from '../../services/apply-theme.service';
import { AccessControlService } from 'src/app/core/services/access-control.service';
import { DataService } from '../../services/data.service';
import { ConstantsService } from '../../services/constants.service';
import { MessageItemType } from '../messages/message.model';
import { AccessControls } from 'src/app/core/models/user.permission.model';
export class ClientModel {
  id: any = null;
  companyName: string = "";
  senderEmail: string = "";
  brand: BrandModel[] = [];
}

export class BrandModel {
  brandName: string = "";
  brandShort: string = "";
  brandCode: string = "";
  id: any = null;
}

@Component({
  selector: 'app-manage-client',
  templateUrl: './manage-client.html',
  styleUrls: ['./manage-client.scss'],
})
export class AddNewClientComponent implements OnInit {  
  @Output() closeClientWindow = new EventEmitter<object>();
  accessControls: AccessControls;
  clientModel!: ClientModel;
  existingBrandsCount: number = 0;
  initialState: any;
  bsModalRef?: BsModalRef;

  constructor(public applyThemeService: ApplyThemeService,
    private accessControlService: AccessControlService,
    private dataService: DataService) {
    this.accessControls = this.accessControlService.configAccess(
      ConstantsService.BADGE_UPDATE
    );
  }

  ngOnInit(): void {
    this.clientModel = new ClientModel;
    if(this.initialState && !this.initialState.isAddClientWindow) {
      this.initialiseEditWindow(this.initialState.selectedClient);
    }
  }

  initialiseEditWindow(selectedClient: ClientModel) {
    One2FiveLoadingService.show();
    this.existingBrandsCount = 0;
    this.dataService.previewCompanydetails(selectedClient.id).subscribe((_companyDetails: any) => {
      One2FiveLoadingService.hide();

      if (_companyDetails.HasErrors) return;
      this.clientModel = _companyDetails;
      this.existingBrandsCount = this.clientModel.brand.length;
    }, (error: any) => {
      One2FiveLoadingService.hide();
      MessagesService.showFailureAlert(error);
    });
  }

  onAddNewBrand() {
    this.clientModel.brand.push(new BrandModel);
  }

  onRemoveBrand(bIndex: number) {
    this.clientModel.brand.splice(bIndex, 1);
  }

  onSubmitClientClick(addNewClientForm: NgForm) {
    if (addNewClientForm.invalid) {
      MessagesService.alertMessages("Please enter all required and valid fields", "", "Validation Alert", MessageItemType.Warning);
      return;
    }
    if (this.initialState.isAddClientWindow) {
      this.submitClientDetails();
    } else {
      this.updateClientDetails();
    }
  }

  allowOnlySpace(inputControl: NgModel) {
    const inputValue = inputControl.control.value;
    if (isEmpty(inputValue)) {
      inputControl.control.setErrors({ error: "Field Required." });
    } else if (!isSpecialCharAndNumberFound(inputValue)) {
      inputControl.control.setErrors({ error: "Numbers and special characters not allowed." });
    } else {
      inputControl.control.setErrors(null);
    }
  }

  checkUniqueInput(inputControl: NgModel, rowIndex: number, propertyName: any = "brandName") {
    const inputValue = inputControl.control.value;
    let brandIndex = -1, isEmptyValue: boolean = false, containSpace: boolean = false;
    if (propertyName == "brandCode") {
      brandIndex = this.clientModel.brand.findIndex((_brand: any) => _brand[propertyName] == inputValue);
      if (inputValue === null) {
        isEmptyValue = true;
      }
    } else {
      brandIndex = this.clientModel.brand.findIndex((_brand: any) => _brand[propertyName].toLowerCase() == inputValue.toLowerCase());
      if (isEmpty(inputValue)) {
        isEmptyValue = true;
      }
      if (isSpacePresent(inputValue)) {
        containSpace = true;
      }
    }

    if (isEmptyValue) {
      inputControl.control.setErrors({ error: "Field Required." });
    } else if (rowIndex != brandIndex) {
      inputControl.control.setErrors({ error: "Duplicate Input." });
    } else if (containSpace) {
      inputControl.control.setErrors({ error: "Space not Allowed." });
    } else if (propertyName == "brandCode") {
      if (inputValue.toString().length != 4) {
        inputControl.control.setErrors({ error: "Should be 4 digits." });
      } else
        inputControl.control.setErrors(null);
    } else {
      inputControl.control.setErrors(null);
    }
  }

  private submitClientDetails() {
    One2FiveLoadingService.show();
    this.dataService.submitCompanydetails(this.clientModel).subscribe(_saveClient => {
      One2FiveLoadingService.hide();
      if (_saveClient.HasErrors) {
        if (_saveClient.Errors[0].Code == "ERROR_COMPANY_ALREADY_PRESENT" || _saveClient.Errors[0].Code == "ERROR_BRAND_ALREADY_PRESENT"
          || _saveClient.Errors[0].Code == "ERROR_COMPANY_NOT_FOUND") {
          MessagesService.showFailureAlert(_saveClient.Errors[0].Message);
        } else {
          MessagesService.showFailureAlert(_saveClient.Errors[0].Message ?? "Error while Adding new Client");
        }
        return;
      }
      const current_user = sessionStorage.getItem("currentUser");
      const current_userjson = One2FiveUtils.parseString(current_user);
      current_userjson.userProfile.companyName = this.clientModel.companyName;
      sessionStorage.setItem("currentUser", One2FiveUtils.stringifyObject(current_userjson));
      window.location.reload();
    }, (error: any) => {
      One2FiveLoadingService.hide();
      MessagesService.showFailureAlert(error);
    });
  }

  private updateClientDetails() {
    One2FiveLoadingService.show();
    this.dataService.updateCompanydetails(this.clientModel).subscribe(_updateClient => {
      One2FiveLoadingService.hide();
      if (_updateClient.HasErrors) {
        if (_updateClient.Errors[0].Code == "ERROR_COMPANY_ALREADY_PRESENT" || _updateClient.Errors[0].Code == "ERROR_BRAND_ALREADY_PRESENT"
          || _updateClient.Errors[0].Code == "ERROR_COMPANY_NOT_FOUND") {
          MessagesService.showFailureAlert(_updateClient.Errors[0].Message);
        } else {
          MessagesService.showFailureAlert(_updateClient.Errors[0].Message ?? "Error while Updating Client");
        }
        return;
      }
      this.closeClientWindow.emit();
      MessagesService.successAlert("Client Updated successfully");
    }, (error: any) => {
      One2FiveLoadingService.hide();
      MessagesService.showFailureAlert(error);
    });
  }

  onCloseUpdateWindow(): void {
    this.closeClientWindow.emit();
  }

}
