import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { CommonUtilService } from './core/services/common-util.service';
import { AccessControlService } from './core/services/access-control.service';
import { ApplyThemeService } from './shared/services/apply-theme.service';
import { One2FiveHelperService } from './shared/services/one2five-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription[] = [];
  isPermisionsLoaded: boolean = false;
  isLoggedIn: boolean = false;
  isThemeLoaded: boolean = false;

  constructor(private translateService: TranslateService,
    public commonUtilService: CommonUtilService,
    public accessControlService: AccessControlService,
    public applyThemeService: ApplyThemeService,
    private one2FiveHelperService: One2FiveHelperService,
    private readonly cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    const lang: any = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en';
    this.commonUtilService.changeLocale(lang);
    this.translateService.use(lang);
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.accessControlService.onLoadPermissionGroup$.subscribe(_permissions => {
      if (!_permissions) return;
      this.isPermisionsLoaded = true;
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.accessControlService.onIsLoggedIn$.subscribe(_isLoggedIn => {
      this.isLoggedIn = _isLoggedIn;
      this.cdr.detectChanges();
    }));
    
    this.subscriptions.push(this.applyThemeService.loadNewTheme$.subscribe(_isThemeLoaded => {
      this.isThemeLoaded = _isThemeLoaded;
      this.cdr.detectChanges();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(_subscription => _subscription.unsubscribe());
  }

  @HostListener('window:onbeforeunload', ['$event'])
  clearLocalStorage(_event: any) {
    const locale: any = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en';
    localStorage.clear();
    this.commonUtilService.changeLocale(locale);
    sessionStorage.clear();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.one2FiveHelperService.escapeKeyPress(true);
    }
  }

}
