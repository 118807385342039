import { Output, Component, OnInit, EventEmitter, Input, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { AngularMultiSelect } from "angular2-multiselect-dropdown";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";

import { HeaderData, IIdItemNamePair, LanguageModel, One2FiveConstant } from "../../models/referance.model";
import { AutoLogoutService } from "src/app/core/services/auto-logout.service";
import { AuthenticationService } from "src/app/core/authentication/authentication.service";
import { One2FiveUtils, delay, getCompanyName, getCurrentUser, getUserProfileDetails } from "src/app/core/services/one2five-utils";
import { MessagesService } from "../messages/messages.service";
import { CommonUtilService } from "src/app/core/services/common-util.service";
import { environment } from "src/environments/environments";
import { DropdownOptionsSettingsService } from "../../services/dropdown-options-settings";
import { ConstantsService } from "../../services/constants.service";
import { AccessControlService } from "src/app/core/services/access-control.service";
import { ApplyThemeService } from "../../services/apply-theme.service";
import { DataService } from "../../services/data.service";
import { StateManagementService } from "../../services/state.service";
import { SecurityService } from "src/app/core/services/security.service";
import { AccessControls } from "src/app/core/models/user.permission.model";
import { UserProfileModel } from "src/app/core/models/user-profile.model";
import { logingActivityRequest } from "../../models/request.interface";
import { MessageItemType } from "../messages/message.model";
import { AddNewClientComponent } from "../manage-client/manage-client";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [BsModalService, AuthenticationService, AutoLogoutService]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild("companyMultiSelect") companyMultiSelect!: AngularMultiSelect;
  @Output("countryChange") countryChange = new EventEmitter<any[]>();
  @Input("show") show: boolean = false;

  one2FiveConstant = One2FiveConstant;
  showWindowForEmail: boolean = false;
  headerData: HeaderData[] = [];

  editProfileVisible: boolean = false;
  changePasswordVisible: boolean = false;
  changeThemeVisible: boolean = false;
  smallProfilePic = "/assets/images/profilePicSmall.png";
  logoImage = "/assets/images/MSM Digital/smalllogo.png";
  profileMenuClick: boolean = false;
  changePassSuccess: boolean = false;

  selectedCompanyId: number = -1;
  selectedCompanyName: string = "";
  companyList: any[] | IIdItemNamePair[] = [];
  companyListSelectedItems: IIdItemNamePair[] = [];
  companyDropdownSettings = {};
  selectedCompany: any[] = [];

  marketList: any[] = [];
  marketListSelectedItems: any[] = [];
  marketDropdownSettings = {};
  selectedMarkets: any[] = [];
  isCompanyMenuAccess: boolean = true;
  userProfile = new UserProfileModel;

  url_Taken: any = "";
  _subscriptions = new Subscription();
  languageCodeSelected: any[] = [{ 'languageCode': 'en', 'itemName': 'EN', 'path': './assets/images/flags/en.jpg' }];
  languageCodeSettings = {};
  languageCodeList = [{ 'languageCode': 'en', 'itemName': 'EN', 'path': './assets/images/flags/en.jpg' }];
  languages: LanguageModel[] = [];
  isMultiLanguageSetupAccess = false;
  clientAccessControls!: AccessControls;

  isCompanyEditBtnClicked: boolean = false;
  loggedInUserCompany: string = "";
  bsModalRef?: BsModalRef;

  constructor(public router: Router,
    private translate: TranslateService,
    private modalService: BsModalService,
    private dataService: DataService,
    public applyThemeService: ApplyThemeService,
    private accessControlService: AccessControlService,
    private securityService: SecurityService,
    public commonUtilService: CommonUtilService,
    private stateManagementService: StateManagementService) {
    const lang: any = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en';
    this.translate.getTranslation(lang);
    this.clientAccessControls = new AccessControls;
    this.loggedInUserCompany = getCompanyName();

    this.commonUtilService.updateLanguageList$.subscribe(res => {
      if (res.length == 0) return;
      res.forEach((langElement: any) => {
        langElement.languageCode = langElement.languageCode.toLowerCase();
        langElement["itemName"] = langElement.languageCode.toUpperCase();
        langElement.path = `./assets/images/flags/${langElement.languageCode.toLowerCase()}.jpg`;
      });
      res.sort((a: any, b: any) => (a.languageName > b.languageName ? -1 : 1));
      this.languages = One2FiveUtils.objectClone(res);
    });
  }

  async ngOnInit() {
    this._subscriptions.add(this.router.events.subscribe((_event: any) => {
      if (_event instanceof NavigationEnd) {
        if (_event.url !== this.url_Taken) {
          let postData: logingActivityRequest = {
            pageUrl: window.location.href
          };
          this.accessControlService.sendlogingActivity(postData).subscribe((_data: any) => {
          }, (_error: any) => {
            MessagesService.errorMessage(_error);
          });
          this.url_Taken = _event.url ?? "";
        }
      }
    })
    );

    this.userProfile = getUserProfileDetails();

    this._subscriptions.add(this.accessControlService.onLoadPermissionGroup$.subscribe(_permissions => {
      if (!_permissions) return;

      this.isMultiLanguageSetupAccess = this.accessControlService.configAccess(ConstantsService.MULTI_LANGUAGE_SETUP).isRead;
      this.clientAccessControls = this.accessControlService.configAccess(ConstantsService.CREATE_CLIENT);

      if (this.isMultiLanguageSetupAccess) {
        this.getLanguages();
      }
      if (this.accessControlService.getUserRole().toUpperCase() === One2FiveConstant.Moderator.toUpperCase()) {
        this.marketDropdownSettings = DropdownOptionsSettingsService.getSSBrandSitesSettings();
      } else {
        this.marketDropdownSettings = DropdownOptionsSettingsService.getBrandSitesSettings();
      }

      this.isCompanyMenuAccess = this.accessControlService.configAccess(ConstantsService.SPECIAL_PERMISSIONS).isRead;
      if (this.isCompanyMenuAccess) {
        this.dataService.getCompany().subscribe((companies: any) => {
          this.companyList = companies;
          if (this.userProfile?.companyName) {
            this.companyListSelectedItems = this.companyList.filter(_company => _company.itemName === this.userProfile.companyName);
            this.selectedCompanyId = this.companyListSelectedItems[0]?.id;
            this.selectedCompanyName = this.companyListSelectedItems[0]?.itemName;
          }
          if (this.clientAccessControls.isCreate) {
            this.companyList.push({ id: One2FiveConstant.newclient, itemName: '+ New Client' });
            this.companyList.unshift(this.companyList.pop());
          }
        });
      }
    }));

    localStorage.setItem("lastAction", Date.now().toString());
    this.companyDropdownSettings = DropdownOptionsSettingsService.getSingleSelectCompaniesSettings();

    this.showWindowForEmail = false;
    if (!this.userProfile?.email) {
      await delay(5000);
      this.showWindowForEmail = true;
    }

    this.dataService.getHeaderData().subscribe((data: any) => {
      this.headerData = data;
    });

    if (this.userProfile?.imagePath) {
      this.smallProfilePic = environment.apiUrl + this.userProfile.imagePath;
    }

    if (!this.show) return;
    this.stateManagementService.getCountrySelect().subscribe((countries: any) => {
      let i = 0;
      countries.forEach((_country: any) => {
        if (_country.itemName != null) {
          this.marketList.push(_country);
          _country["languageCode"] = i++;
        }
      });
    });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  validateFields(): boolean {
    if (!this.userProfile?.emailId) return false;

    const regexrEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.userProfile.emailId.match(regexrEmail)) {
      MessagesService.alertMessages("ERRORS.Please Enter valid email", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    return true;
  }

  onSubmitEmail() {
    if (!this.validateFields()) return;

    const dataObject: any = {};
    dataObject["email"] = this.userProfile.emailId;
    this.dataService.updateProfile(dataObject).subscribe(async (data: any) => {
      if (data["HasErrors"]) {
        MessagesService.errorAlert("ERRORS.Email not updated. You can still go to edit profile to update email");
        this.closeEmailView();
      } else {
        let current_user: any = getCurrentUser();
        current_user.userProfile.email = this.userProfile.emailId;
        sessionStorage.setItem("currentUser", One2FiveUtils.stringifyObject(current_user));
        MessagesService.successAlert("Email updated successfully Thank you");
        this.closeEmailView();
      }
    });
  }

  clickedInside(_event: any) {
    _event.preventDefault();
    _event.stopPropagation();
    this.profileMenuClick = !this.profileMenuClick;
  }

  @HostListener("document:click", ["$_event"])
  clickedOutside(_event: any) {
    if (this.profileMenuClick) {
      this.profileMenuClick = false;
    }
  }

  onLogOut() {
    this.securityService.logoutUser();
  }

  closeEmailView() {
    this.showWindowForEmail = !this.showWindowForEmail;
  }

  onMarketSelect(_countryValue: any) {
    this.trimList();
    this.countryChange.emit(this.selectedMarkets);
  }
  onMarketDeSelect(_countryValue: any) {
    this.trimList();
    this.countryChange.emit(this.selectedMarkets);
  }
  onMarketSelectAll(_countryValue: any) {
    this.trimList();
    this.countryChange.emit(this.selectedMarkets);
  }
  onMarketDeSelectAll(_countryValue: any) {
    this.trimList();
    this.countryChange.emit(this.selectedMarkets);
  }
  trimList() {
    this.selectedMarkets = [];
    this.marketListSelectedItems.forEach((market) => {
      this.selectedMarkets.push(market.regionName);
    });
  }

  onCloseModal() {
    this.bsModalRef?.hide();
  }

  onCompanySelect(_event: any) {
    if (this.isCompanyEditBtnClicked) return; // if clicked on edit button of client then must open the edit Client popup

    if (_event.id == One2FiveConstant.newclient) {
      this.companyListSelectedItems = this.companyList.filter(_company => _company.itemName === this.userProfile.companyName);
      this.selectedCompanyId = this.companyListSelectedItems[0].id;
      this.selectedCompanyName = this.companyListSelectedItems[0].itemName;
      this.companyListSelectedItems = One2FiveUtils.objectClone(this.companyListSelectedItems);

      const initialState: ModalOptions = {
        initialState: {
          isAddClientWindow: true,
          popupTitle: "Add New Client"
        }
      };
      this.bsModalRef = this.modalService.show(AddNewClientComponent, { initialState });
      return;
    }

    const current_user = sessionStorage.getItem("currentUser");
    const current_userjson = One2FiveUtils.parseString(current_user);
    current_userjson.userProfile.companyName = _event.itemName;

    sessionStorage.setItem("currentUser", One2FiveUtils.stringifyObject(current_userjson));
    window.location.reload();
  }

  onCompanyDeSelect(_event: any) {
    const current_user = sessionStorage.getItem("currentUser");
    const current_userjson = One2FiveUtils.parseString(current_user);
    current_userjson.userProfile.companyName = _event.itemName;
    sessionStorage.setItem("currentUser", One2FiveUtils.stringifyObject(current_userjson));
    if (this.userProfile.companyName) {
      this.companyListSelectedItems = this.companyList.filter(_company => _company.itemName === this.userProfile.companyName);
    }
  }

  async onOpenClientDetails(_event: any) {
    this.companyMultiSelect.clearSelection();
    this.isCompanyEditBtnClicked = true;
    const initialState: ModalOptions = {
      initialState: {
        isAddClientWindow: false,
        popupTitle: "Edit Client",
        selectedClient: _event
      }
    };
    this.bsModalRef = this.modalService.show(AddNewClientComponent, { initialState });
    await delay(500);
    this.isCompanyEditBtnClicked = false;
  }

  onEditProfile() {
    this.editProfileVisible = true;
    this.profileMenuClick = false;
  }
  closeEditProfile() {
    this.editProfileVisible = false;
    this.refresh();
  }

  onChangePassword() {
    this.changePasswordVisible = true;
    this.profileMenuClick = false;
  }
  async changePassShowMsg() {
    this.changePassSuccess = true;
    await delay(5000);
    this.changePassSuccess = false;
  }
  closeChangePassword() {
    this.changePasswordVisible = false;
  }

  onChangeTheme() {
    this.changeThemeVisible = true;
    this.profileMenuClick = false;
  }
  closeChangeTheme() {
    this.changeThemeVisible = false;
  }

  refresh() {
    if (this.userProfile?.imagePath) {
      this.smallProfilePic = environment.apiUrl + this.userProfile.imagePath;
    }
  }

  private getLanguages() {
    this.dataService.getLanguageList().subscribe((res: any) => {
      if (!res[`HasErrors`]) {
        res.languageList = res.languageList.filter((a: any) => a.active);
        res.languageList.sort((a: any, b: any) => (a.languageName > b.languageName ? -1 : 1));
        res.languageList.forEach((langElement: any) => {
          langElement.languageCode = langElement.languageCode.toLowerCase();
          langElement["id"] = langElement.languageCode.toLowerCase();
          langElement["itemName"] = langElement.languageCode.toUpperCase();
          langElement.path = `./assets/images/flags/${langElement.languageCode.toLowerCase()}.jpg`;
        });
        this.languages = One2FiveUtils.objectClone(res.languageList);
        this.setSelectedLanguage(res.languageList);
      } else {
        const language = new LanguageModel();
        language.active = true;
        language.languageCode = 'en';
        language["id"] = "en";
        language["itemName"] = "EN";
        language.languageName = "English";
        language.path = "./assets/images/flags/en.jpg";
        this.languages.push(language);
        this.setSelectedLanguage();
      }
    }, (_err: HttpErrorResponse) => {
      const language = new LanguageModel();
      language.active = true;
      language.languageCode = 'en';
      language["id"] = "en";
      language["itemName"] = "EN";
      language.languageName = "English";
      language.path = "./assets/images/flags/en.jpg";
      this.languages.push(language);
      this.setSelectedLanguage();
      throw new Error("Error while fetching languages.");
    });
  }

  private setSelectedLanguage(languages = this.languages) {
    const lang = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en';
    this.languageCodeSettings = {
      singleSelection: true,
      text: lang,
      enableCheckAll: false,
      enableSearchFilter: false,
      classes: "ddCustomClass custom-class clslanguage",
      lazyLoading: true,
      badgeShowLimit: 1,
      maxHeight: 175
    };
    this.languageCodeSelected = languages.filter(element => element.languageCode == lang);
  }

  public onLanguageSelection(_event: any) {
    const preLang: any = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en';
    localStorage.setItem("previousLanguage", preLang);
    this.commonUtilService.changeLocale(_event.languageCode);
  }

  public onLanguageDeSelection(_event: any) {
    const preLang: any = localStorage.getItem("previousLanguage") ? localStorage.getItem("previousLanguage") : 'en';
    const currLang: any = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en';
    localStorage.setItem("previousLanguage", currLang);
    this.commonUtilService.changeLocale(preLang);
    this.languageCodeSelected = this.languages.filter(element => element.languageCode == preLang);
  }

}
