import { Injectable } from "@angular/core";

import { IIdItemNamePair, IIdItemNameSelectedPair, IIdItemNameStringPair } from "../models/referance.model";

@Injectable({ providedIn: "root" })
export class AppLocalStoreService {
    public static getCustomerList() {
        return [
            { id: 0, itemName: "Electrolux" },
            { id: 1, itemName: "Electrolux-Home" }
        ];
    }

    public static getActiveInactivePair(): IIdItemNamePair[] {
        return [
            { id: 1, itemName: "Active" },
            { id: 2, itemName: "Inactive" }
        ];
    }
    public static getActiveInactiveStringStatus(): IIdItemNameStringPair[] {
        return [
            { status: "Active", itemName: "Active" },
            { status: "Inactive", itemName: "Inactive" }
        ];
    }

    public static getActiveInactiveStatus() {
        return [
            { status: "Active" },
            { status: "Inactive" }
        ];
    }

    public static getStatusList() {
        return [
            { status: "Active" },
            { status: "Inactive" },
            { status: "Incomplete" }
        ];
    }

    public static getPieTypes() {
        return [
            { id: 1, itemName: "Regular" },
            { id: 2, itemName: "Blast" }
        ];
    }

    public static getYesNoBothList(): IIdItemNamePair[] {
        return [
            { id: 1, itemName: "Yes" },
            { id: 2, itemName: "No" },
            { id: 3, itemName: "Both" }
        ];
    }

    public static getReivewOrigins(): IIdItemNamePair[] {
        return [
            { id: 1, itemName: "PIE" },
            { id: 2, itemName: "Brand Site" },
            { id: 3, itemName: "Campaign" },
            { id: 4, itemName: "Any" },
        ];
    }

    public static getFirstLevelIntervals(): IIdItemNamePair[] {
        return [
            { id: 1, itemName: "Daily" },
            { id: 2, itemName: "Weekly" },
            { id: 3, itemName: "Monthly" },
            { id: 4, itemName: "Custom" },
        ];
    }

    public static getSecondLevelIntervals(): IIdItemNamePair[] {
        return [
            { id: 1, itemName: "Days" },
            { id: 2, itemName: "Weeks" },
            { id: 3, itemName: "Months" },
        ];
    }

    public static getWinnerPerSendOut(): IIdItemNamePair[] {
        return [
            { id: 1, itemName: "Incentivized" },
            { id: 2, itemName: "Sweepstake" },
        ];
    }

    public static getSimpleComplexTypes(): IIdItemNamePair[] {
        return [
            { "id": 1, "itemName": "Simple" },
            { "id": 2, "itemName": "Complex" }
        ];
    }

    public static getApprovedPendingStatus(): IIdItemNamePair[] {
        return [
            { id: 0, itemName: "Approved" },
            { id: 1, itemName: "Pending" }
        ];
    }

    public static getApprovedRejectedStatus(): IIdItemNamePair[] {
        return [
            { id: 0, itemName: 'Approved' },
            { id: 1, itemName: 'Rejected' },
            { id: 2, itemName: 'Open' },
            { id: 3, itemName: 'Hold' }
        ];
    }

    public static getTrueFalseStatus(): IIdItemNamePair[] {
        return [
            { id: 0, itemName: 'True' },
            { id: 1, itemName: 'False' }
        ];
    }

    public static getOffSimpleComplexStatus(): IIdItemNamePair[] {
        return [
            { id: 1, itemName: 'Off' },
            { id: 2, itemName: 'Simple' },
            { id: 3, itemName: 'Complex' },
        ];
    }

    public static getOne2FiveList(): IIdItemNamePair[] {
        return [
            { id: 1, itemName: '1' },
            { id: 2, itemName: '2' },
            { id: 3, itemName: '3' },
            { id: 4, itemName: '4' },
            { id: 5, itemName: '5' }
        ];
    }

    public static getReviewOperators(): IIdItemNamePair[] {
        return [
            { id: "all", itemName: "All" },
            { id: "eq", itemName: "Equals to" },
            { id: "lt", itemName: "Less than" },
            { id: "gt", itemName: "Greater than" },
            { id: "ne", itemName: "Not Equals to" }
        ];
    }

    public static getReviewAnswers(): IIdItemNameSelectedPair[] {
        return [
            { id: 1, selected: false, itemName: 'With Answers' },
            { id: 2, selected: false, itemName: 'Without Answers' },
        ];
    }

    public static getAllSubscriptionTypes(): IIdItemNamePair[] {
        return [
            {
                id: "MONTHLY",
                itemName: "Monthly"
            },
            {
                id: "YEARLY",
                itemName: "Yearly"
            }
        ]
    }

}