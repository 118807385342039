export interface IIdTextPair {
	id: number;
	text: string;
}

export interface IIdItemNameStringPair {
	status: string;
	itemName: string;
}

export interface IIdItemNameSelectedPair {
	id: number;
	itemName: string;
	selected: boolean;
}
export interface IIdItemNamePair {
	id: any;
	itemName: string;
}

export interface IdTextCategoryModel {
	text: string;
	id: string;
	categoryID: string;
}

export class LanguageModel {
	languageId: number | null = null;
	languageCode: string | null = null;
	languageName: string | null = null;
	active: boolean | null = false;
	path: string | null = null;
	value: any | null = null;
	id: string = "";
	itemName: string = "";
}

export interface languageData {
	languageId: number;
	languageCode: string;
	languageName: string;
}

export interface CountryData {
	countryId: number;
	countryCode: string;
	countryName: string;
}
export interface MenuBarData {
	menuText: string;
	menuUrl: string;
	menuId: string;
	iconUrl: string;
	subMenuData: MenuBarData[];
}

export interface HeaderData {
	headerItemIcon: string;
	headerItemText: string;
	headerItemUrl: string;
}

export interface IIDStatusModel {
	id: number;
	status: string;
}
export interface ReviewStatusGrouping {
	Approved: number;
	Rejected: number;
	Open: number;
}

export type StringNullType = string | null;
export type BooleanNullType = boolean | null;
export type NumberNullType = number | null;
export type DateNullType = Date | null;
export type StringDateNullType = string | Date | null;

export const One2FiveConstant = {
	Answer: "Answer",
	all: "all",
	Add: "Add",
	Create: "Create",
	Submit: "Submit",
	Edit: "Edit",
	Update: "Update",
	Remove: "Remove",
	Delete: "Delete",
	Active: 'Active',
	Inactive: 'Inactive',
	none: "none",
	block: "block",
	Yes: "Yes",
	No: "No",
	Other: "Other",
	Any: "Any",
	both: "both",
	Both: "Both",
	Weekly: "Weekly",
	Days: "Days",
	Custom: "Custom",
	PIE: "PIE",
	Campaign: "Campaign",
	BrandSite: "Brand Site",
	REVIEW: "REVIEW",
	Product : "Product",
    Service: "Service",
	PRODUCT : "PRODUCT",
	SERVICE: "SERVICE",
	Approved: "Approved",
	approved: "approved",
	Accepted: "Accepted",
	Rejected: "Rejected",
	rejected: "rejected",
	Open: "Open",	
	Pending: "Pending",
	PendingNow: "PendingNow",	
	multipleAccept: "multipleAccept",
	multipleReject: "multipleReject",
	moderator: "moderator",
	Moderator: "Moderator",
	CountryManager: "Country Manager",
	Off: "Off",
	Clear: "Clear",
	Next: "Next",
	Previous: "Previous",
	Finish: "Finish",
	view: "view",
	submit: "submit",
	Cancel: "Cancel",
	open: "open",
	pendingnow: "pendingnow",
	QUESTION: "QUESTION",
	Market: "Market",
	Retailer: "Retailer",

	Description: "Description",
	Mr: "Mr",
	Moderation: "Moderation",
	Verification: "Verification",
	General: "General",
	Comparison: "Comparison",

	newclient: "newclient"
}

