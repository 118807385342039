<div id="confirmationWindow" class="modal modal-md" [ngStyle]="{ 'display': confirmDisplayStyle }">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header"
        [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
        <span class="modal-title"> {{ messageModel.title }}</span>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        {{ messageModel.message }}?
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-primary button_margin" (click)="onYesConfirmation()"
          [ngStyle]="{
          'background-color': applyThemeService.$themeColor, 'border-color': applyThemeService.$themeColor, color: applyThemeService.$textColor}">
          {{ "Yes" | translate }}
        </button>
        <button type="button" class="btn btn-default customBtnCancel" (click)="onCancelConfirmationWindow()">
          {{ "No" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-md" id="alertModal" #alertModalRef [ngStyle]="{ 'display': alertDisplayStyle }">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-header"
        [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
        <span class="modal-title"> {{ messageModel.title }}</span>
      </div>

      <div class="modal-body">
        {{ messageModel.message }}..
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" [ngStyle]="{ 'background-color': applyThemeService.$themeColor,
        'border-color': applyThemeService.$themeColor, 'color': applyThemeService.$textColor}"
          (click)="onCloseAlert()">
          {{ "BUTTONS.Close" | translate }}
        </button>
      </div>

    </div>
  </div>
</div>


<div class="errorDiv" *ngIf="visibleFailureAlert">
  <h1 class="errorMsg">{{ failureAlert | translate }}</h1>
</div>
<div class="alert alert-success successAlert" [class.d-none]="!visibleSuccessAlert" role="alert"
  innerHTML="{{ successAlert | translate }}!">
</div>
<div class="alert alert-danger dangerAlert" [class.d-none]="!visibleErrorAlert" role="alert"
  innerHTML="{{ errorAlert | translate }}!">
</div>

<div [class.d-none]="!visiblePopupError" class="alert alert-danger dangerAlert popupMessage" role="alert">
  {{ popupAlert | translate }}
</div>

<div *ngIf="visibleSuccessMessaage"
  class="alert alert-success alert-dismissible show successAlert clsResponseDetailList" role="alert">
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onCloseMessageAlert()">
    <span aria-d-none="true">&times;</span>
  </button>

  <div *ngFor="let message of messages">{{ message }}</div>

</div>