<div class="modal-dialog modal-sm modal-xl">
  <div class="modal-content modal-container">
    <div class="modal-header D-F JC_SB"
      [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
      <div class="modalHeaderTitle">
        {{ "Passkey Details" | translate }}
      </div>
      <button class="transparentButton"
        [ngStyle]="{ 'background-color': applyThemeService.$secondaryColor, color: applyThemeService.$textColor }"
        (click)="onCloseModalWindowClick()">
        <span class="icon icon-cancel"></span>
      </button>
    </div>

    <div class="modal-body">
      <div *ngIf='visibleMarketPopup'>
        <div class="row AI_C">
          <div class="col-md-9">
            <h4>{{ marketData.marketName }}</h4>
          </div>
          <div class="col-md-3" (click)="onMarketPassKeyClick()">
            <div class="D-F JC_SE p-1 cursorPointer" (mouseover)="one2FiveHelper.changeStyle($event)"
              (mouseout)="one2FiveHelper.changeStyle($event)"
              [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
              <span>{{ "New PassKey" | translate }}</span>
              <span [ngStyle]="{transform: expandFilterPanel ? 'rotate(90deg)' : 'rotate(0deg)'}">
                <span class="icon icon-right-arrow" [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 
                color: applyThemeService.$textColor}"></span>
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="expandMarketFilterPanel" class="mt-1 border1">
          <button id="closeIcon" (click)="expandMarketFilterPanel=false"
            [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
            <span>X</span>
          </button>
          <div class="row AI_C">
            <div class="col-lg-5 col-md-5">
              <div class="row AI_C">
                <label for="descriptionInput" class="col-md-3 col-lg-3 fs-6">
                  {{ "Description" | translate }}
                </label>
                <input type="text" #descriptionInputRef autofocus class="col-md-9 col-lg-9 boxShadow"
                  [(ngModel)]="passKeyDescription" placeholder="{{'Enter'|translate}} {{'Description' | translate}}">
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="row AI_C">
                <div class="col-lg-4 col-md-4">
                  <label for="syndicationToggle" class="col-md-9 col-lg-9 fs-6">
                    {{ "Syndication" | translate }}</label>
                  <ui-switch class="col-md-3 col-md-3" [(ngModel)]="passKeySyndication"></ui-switch>
                </div>
                <div class="col-lg-5 col-md-5">
                  <label for="syndicationToggle" class="col-md-9 col-lg-9 fs-6">
                    {{ "Product Grouping" | translate }}</label>
                  <ui-switch class="col-md-3 col-lg-3" [(ngModel)]="includeProductGrouping"></ui-switch>
                </div>
                <div class="col-lg-3 col-md-3">
                  <label for="passKeyEnabledToggle" class="col-md-8 col-lg-8 fs-6"> {{ "Enabled" | translate }}</label>
                  <ui-switch class="col-md-4 col-lg-4" [(ngModel)]="passKeyEnabled"></ui-switch>
                </div>
              </div>
            </div>
            <div class="col-lg-1 col-md-1">
              <div class="submitFilterBtn border-radius-4"
                [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }"
                (click)="onCreateMarketPassKeyClick('submit')">
                <span>{{ "BUTTONS.Submit" | translate }}</span>
              </div>
            </div>
          </div>
        </div>

        <table class="table table-responsive table-hover border1 mt-2">
          <thead>
            <tr>
              <th class="T_AC w950px">{{ "Description" | translate }} </th>
              <th class="T_AC w800px">{{ "Pass" | translate }} {{ "Key" | translate }} </th>
              <th class="T_AC w350px">{{ "Created" | translate }} {{ "Time" | translate }} </th>
              <th class="T_AC w100px">{{ "Syndication" | translate }} </th>
              <th class="T_AC w100px">{{ "Product Grouping" | translate }} </th>
              <th class="T_AC w100px">{{ "Enabled" | translate }} </th>
              <th class="T_AC w250px">{{ "Action" | translate }} </th>
            </tr>
          </thead>
          <tbody [formGroup]="marketPassKeyForm" class="clstable_body">
            <ng-container formArrayName="marketPassKeyFields">
              <ng-container *ngFor="let passKeys of marketPassKeyFields.controls; let idx = index">
                <tr [formGroup]="passKeys">
                  <td class="T_AC w450px">
                    <textarea formControlName="description" class="w-100 H-27"
                      placeholder="{{'Enter'|translate}} {{'Description' | translate}}" focus="true"></textarea>
                  </td>
                  <td class="T_AC w300px">
                    <input type="text" formControlName="apiPassKey" class="w-100">
                  </td>
                  <td class="T_AC w200px">
                    <input type="text" formControlName="requestTime" class="w-100">

                  </td>
                  <td class="T_AC w100px">
                    <ui-switch formControlName="syndication"></ui-switch>
                  </td>
                  <td class="T_AC w100px">
                    <ui-switch formControlName="includeProductGrouping"></ui-switch>
                  </td>
                  <td class="T_AC w100px">
                    <ui-switch formControlName="enabled"></ui-switch>
                  </td>
                  <td class="T_AC w100px">
                    <button *ngIf="editableIndex != idx" [title]="'Edit' | translate"
                      (click)="onEditMarketRowClick(idx, passKeys)" class="editaction_btn"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_editactionicon">edit</i>
                    </button>
                    <button *ngIf="editableIndex == idx" [title]="'Cancel' | translate" class="MR-2 action_btn"
                      (click)="onCancelMarketRowClick(idx, passKeys)"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_actionicon">restore</i>
                    </button>
                    <button *ngIf="editableIndex == idx" [title]="'Save' | translate" class="action_btn"
                      (click)="onUpdateMarketRowClick(idx, passKeys)"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_actionicon">save</i>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngIf='visibleRetailerPopup'>
        <div class="row AI_C">
          <div class="col-md-9">
            <h4>{{ retailerData.retailerName}}</h4>
          </div>
          <div class="col-md-3" (click)="openCloseCreateDiv()">
            <div class="D-F JC_SE p-1 cursorPointer" (mouseover)="one2FiveHelper.changeStyle($event)"
              (mouseout)="one2FiveHelper.changeStyle($event)"
              [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
              <span>{{ "New PassKey" | translate }}</span>
              <span [ngStyle]="{transform: expandFilterPanel ? 'rotate(90deg)' : 'rotate(0deg)'}">
                <span class="icon icon-right-arrow" [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 
                color: applyThemeService.$textColor}"></span>
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="expandFilterPanel" class="mt-1">
          <div class="row AI_C border1">
            <div class="col-lg-3 col-md-3">
              <div class="row AI_C">
                <label for="marketList" class="col-md-4 col-lg-4 fs-6 w-100">
                  {{ "Brand Site" | translate }}</label>
                <angular2-multiselect [data]="arrMarketList" [(ngModel)]="marketListSelectedItems"
                  [settings]="marketDropdownSettings" (ngModelChange)="getBrandSite($event)">
                  <c-search>
                    <ng-template>
                      <input type="text" (keyup)="onBrandSearchKeyUp($event)" [placeholder]="'Search Brand'| translate"
                        class="cls_dropdownsearchinput" />
                    </ng-template>
                  </c-search>
                  <c-item>
                    <ng-template let-item="item">
                      <span>{{ item.itemName }}</span>
                    </ng-template>
                  </c-item>
                </angular2-multiselect>
              </div>
            </div>
            <div class="col-md-8 col-lg-8">
              <div class="row AI_C" *ngIf="marketListSelectedItems.length">
                <div class="col-md-6 col-lg-6">
                  <div class="row AI_C">
                    <label for="descriptionInput" class="col-md-3 fs-6"> {{ "Description" | translate
                      }}</label>
                    <input type="text" #descriptionInputRef autofocus class="col-md-9 boxShadow"
                      [(ngModel)]="passKeyDescription"
                      placeholder="{{'Enter'|translate}} {{'Description' | translate}}">
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="row AI_C">
                    <div class="col-lg-6 col-md-6">
                      <label for="syndicationToggle" class="col-md-8 fs-6"> {{ "Syndication" | translate
                        }}</label>
                      <ui-switch class="col-md-4" [(ngModel)]="passKeySyndication"></ui-switch>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <label for="passKeyEnabledToggle" class="col-md-8 fs-6"> {{ "Enabled" | translate
                        }}</label>
                      <ui-switch class="col-md-4" [(ngModel)]="passKeyEnabled"></ui-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-1 col-md-1">
              <div class="submitFilterBtn border-radius-4"
                [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }"
                (click)="onCreateRetailerPassKeyClick()">
                <span>{{ "BUTTONS.Submit" | translate }}</span>
              </div>
            </div>
          </div>
        </div>

        <table class="table table-responsive table-hover border1 mt-2">
          <thead>
            <tr>
              <th class="table_header w-25">{{ "Description" | translate }}</th>
              <th class="table_header w-25">{{ "Pass" | translate }} {{ "Key" | translate }}</th>
              <th class="table_header w-8">{{ "Market" | translate }} </th>
              <th class="table_header w-8">{{ "Status" | translate }}</th>
              <th class="table_header w-6">{{ "Syndication" | translate }}</th>
              <th class="table_header w-10">{{ "Created" | translate }} {{ "Time" | translate }}</th>
              <th class="table_header w-8">{{ "Product" | translate }} {{ "Grouping" | translate }}</th>
              <th class="table_header w-6">{{ "Enabled"| translate}}</th>
              <th class="table_header w-6">{{ "Action" | translate }}</th>
            </tr>
          </thead>

          <tbody [formGroup]="retailerPassKeyForm" class="clstable_body">
            <ng-container formArrayName="retailerPassKeyFields">
              <ng-container *ngFor="let passKeys of retailerPassKeyFields.controls; let idx = index">
                <tr [formGroup]="passKeys">
                  <td id="table_data" class="w-25">
                    <textarea formControlName="description" class="w-100"
                      placeholder="{{'Enter'|translate}} {{'Description' | translate}}" focus="true"></textarea>
                  </td>
                  <td id="table_data" class="w-25">
                    <input type="text" formControlName="apiPassKey" class="w-100">
                  </td>
                  <td id="table_data" class="w-8">
                    <input type="text" formControlName="marketName" class="w-100">
                  </td>
                  <td id="table_data" class="w-8">
                    <input type="text" formControlName="status" class="w-100">
                  </td>
                  <td id="table_data" class="w-6">
                    <ui-switch formControlName="syndication"></ui-switch>
                  </td>
                  <td id="table_data" class="w-10">
                    <input type="text" formControlName="updatedTime" class="w-100">
                  </td>
                  <td id="table_data" class="w-8">
                    <ui-switch formControlName="includeProductGrouping"></ui-switch>
                  </td>
                  <td id="table_data" class="w-6">
                    <ui-switch formControlName="enabled"></ui-switch>
                  </td>
                  <td id="table_data" class="w-6">
                    <button *ngIf="editableIndex != idx" [title]="'Edit' | translate"
                      (click)="onEditRetailerRowClick(idx, passKeys)" class=""
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_actionicon">edit</i>
                    </button>
                    <button *ngIf="editableIndex == idx" [title]="'Cancel' | translate" class=""
                      (click)="onCancelRetailerRowClick(idx, passKeys)"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_actionicon">restore</i>
                    </button>
                    <button *ngIf="editableIndex == idx" [title]="'Save' | translate" class=""
                      (click)="onUpdateRetailerRowClick(idx, passKeys)"
                      [ngStyle]="{ 'background-color': one2FiveHelper.defaultThemeColor(), 'color': applyThemeService.$textColor }">
                      <i class="material-icons cls_actionicon">save</i>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>