import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { getCompanyName, getHeaderPayload } from './one2five-utils';
import { CommonUtilService } from './common-util.service';
import { PermissionGroup, PermissionGroupResponse, PermissionModule } from '../models/user.permission.model';
import { API_URLS, COMMON_HTTP_HEADERS } from 'src/app/shared/constants/api_urls';
import { logingActivityRequest, logingdeviceinfoRequest } from 'src/app/shared/models/request.interface';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  permissionGroup!: PermissionGroup | any;
  isLoggedIn = false;
  isPermisionsLoaded: boolean = false;

  private _onIsLoggedIn: Subject<boolean> = new BehaviorSubject<boolean>(false);
  onIsLoggedIn$: Observable<boolean> = this._onIsLoggedIn.asObservable();
  public onIsLoggedIn(_isLoggedIn: boolean) {
    this._onIsLoggedIn.next(_isLoggedIn);
  }

  private _onLoadPermissionGroup: Subject<any> = new BehaviorSubject<any>(null);
  onLoadPermissionGroup$: Observable<any> = this._onLoadPermissionGroup.asObservable();
  public onLoadPermissionGroup(_permissionLoaded: any) {
    this._onLoadPermissionGroup.next(_permissionLoaded);
  }
  
  constructor(private httpClient: HttpClient,
    private commonUtilService: CommonUtilService) { }

  public getCurrentCompanyName() {
    let companyName = getCompanyName();
    return companyName && companyName.toLocaleLowerCase();
  }


  getAccessConfig(moduleName: string): boolean[] {
    if (!this.configAccess(moduleName).isRead) {
      this.commonUtilService.logoutUser.next(true);
      return [false, false, false];
    } else {
      return [
        this.configAccess(moduleName).isCreate,
        this.configAccess(moduleName).isUpdate,
        this.configAccess(moduleName).isDelete,
      ];
    }
  }

  configAccess(moduleName: string): PermissionModule {
    let tempPermissionGroup: PermissionModule = {
      moduleName,
      isCreate: false,
      isRead: false,
      isUpdate: false,
      isDelete: false
    }; 
    if (!this.permissionGroup) {
      return tempPermissionGroup;
    };
    return this.permissionGroup.modules.find((_module: any) => _module.moduleName === moduleName) || tempPermissionGroup;
  }

  getUserRole(): string {
    return this.permissionGroup && this.permissionGroup['userRole'];
  }

  sendlogingActivity(data: logingActivityRequest) {
    let inputPayload: any = { ...data, ...getHeaderPayload() };
    return this.httpClient.post<any>(API_URLS.logingAndActivityUrl, inputPayload);
  }

  sendlogingdeviceinfo(data: logingdeviceinfoRequest) {
    let inputPayload: any = { ...data, ...getHeaderPayload() };
    return this.httpClient.post<any>(API_URLS.logingAndDeviceinfoUrl, inputPayload);
  }

  getAccessPermissions() {
    this.isPermisionsLoaded = false;
    if (this.permissionGroup) {
      this.isPermisionsLoaded = true;
      return;
    }
    this.httpClient.post<PermissionGroupResponse>(API_URLS.getPermissionsDataURL, getHeaderPayload(), { headers: new HttpHeaders({ ...COMMON_HTTP_HEADERS }) }).subscribe((data) => {
      if (data['HasErrors']) {
        this.commonUtilService.logoutUser.next(true);
      } else {
        this.permissionGroup = data['permissionGroup'];
        this.isPermisionsLoaded = true;
        this.onLoadPermissionGroup(this.permissionGroup);
      }
    }, () => {
      this.commonUtilService.logoutUser.next(true); 
    });
  }

}
