<ul class="menuContainer fullWidth">
  <li *ngIf="menuItemAccess.dashboard" class="menuListItem blackColor mt-p5" [title]="'MENUITEMS.Dashboard' | translate"
    appHoverClass [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor"
    [selectedItem]="selectedItem" [ngStyle]="{
      'background-color': selectedItem == menuItemUrlsConstants.dashboard ? themeTemplate.secondaryColor : themeTemplate.themeColor, color: themeTemplate.textColor
    }">
    <a routerLink="{{'/'+menuItemUrlsConstants.dashboard}}" (click)="onMenuItemClick()"
      [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.dashboard ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}"><span
        class="icon icon-newsandsupport AC_C"></span>
      <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Dashboard' | translate }}</span>
    </a>
  </li>

  <li *ngIf="menuItemAccess.reviewManagement" class="menuListItem blackColor"
    [title]="'MENUITEMS.Review Management' | translate" (click)="openReviews()" appHoverClass
    [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem"
    [ngStyle]="{ 'background-color': selectedItem == 'reviews' ? themeTemplate.secondaryColor : themeTemplate.themeColor, color: themeTemplate.textColor }">
    <a class="d-blockk" [ngStyle]="{ color: themeTemplate.textColor }">
      <span class="icon icon-star AC_C"></span>
      <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Review Management' | translate }}</span>
      <span class=" arrowSize float-end" [ngStyle]="{ transform: isReviewsOpen ? 'rotate(90deg)' : 'rotate(0deg)'}">
        <span class="icon icon-right-hollow-arrow"></span>
      </span>
    </a>
  </li>
  <ul [@slideInOutReviews]="animationReviewsState">
    <li *ngIf="menuItemAccess.products_reviews" class="menuListItem blackColor subMenuItem"
      [title]="'Products' | translate" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.reviewManagementProducts ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.reviewManagementProducts}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.reviewManagementProducts ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-star AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'Product Reviews' | translate }}</span>
      </a>
    </li>
    <li *ngIf="menuItemAccess.services_reviews" class="menuListItem blackColor subMenuItem"
      [title]="'Services' | translate" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.reviewManagementServices ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.reviewManagementServices}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.reviewManagementServices ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-star AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'Service Reviews' | translate }}</span>
      </a>
    </li>
  </ul>

  <li *ngIf="menuItemAccess.questionModeration" class="menuListItem blackColor"
    [title]="'MENUITEMS.Question Moderation' | translate" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
    [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem"
    [ngStyle]="{ 'background-color': selectedItem == menuItemUrlsConstants.questionModeration ? themeTemplate.secondaryColor : themeTemplate.themeColor,olor: themeTemplate.textColor }">
    <a routerLink="{{'/'+menuItemUrlsConstants.questionModeration}}" (click)="onMenuItemClick()"
      [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.questionModeration ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
      <span class="icon icon-question AC_C"></span>
      <span class="menu-item-text overflow-ellipse-wrap"> {{ 'MENUITEMS.Question Moderation' | translate }}</span>
    </a>
  </li>

  <li *ngIf="menuItemAccess.productManagement" class="menuListItem blackColor"
    [title]="'MENUITEMS.Product Management' | translate" (click)="onOpenProducts()" appHoverClass
    [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem"
    [ngStyle]="{
      'background-color': selectedItem == 'product-management' ? themeTemplate.secondaryColor : themeTemplate.themeColor,
      color: themeTemplate.textColor }">
    <a class="d-blockk" [ngStyle]="{ color: themeTemplate.textColor }">
      <span class="icon icon-star AC_C"></span>
      <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Product Management' | translate }}</span>
      <span class=" arrowSize float-end" [ngStyle]="{ transform: isProductsOpen ? 'rotate(90deg)' : 'rotate(0deg)'}">
        <span class="icon icon-right-hollow-arrow"></span>
      </span>
    </a>
  </li>
  <ul [@slideInOutReviews]="animationProductsState">
    <li *ngIf="menuItemAccess.products_overviews" class="menuListItem blackColor subMenuItem"
      [title]="'Product Overview' | translate" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.productOverview ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.productOverview}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.productOverview ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-star AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'Product Overview' | translate }}</span>
      </a>
    </li>
    <li *ngIf="menuItemAccess.services_overviews" class="menuListItem blackColor subMenuItem"
      [title]="'Service Overview' | translate" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.serviceOverview ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.serviceOverview}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.serviceOverview ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-star AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'Service Overview' | translate }}</span>
      </a>
    </li>
  </ul>


  <li *ngIf="menuItemAccess.statistics" class="menuListItem blackColor" [title]="'MENUITEMS.Statistics' | translate"
    appHoverClass [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor"
    [selectedItem]="selectedItem" [ngStyle]="{
      'background-color': selectedItem == 'statistics' ? themeTemplate.secondaryColor : themeTemplate.themeColor,
      color: themeTemplate.textColor
    }">
    <a routerLink="/statistics" (click)="onMenuItemClick()"
      [ngStyle]="{'background-color': selectedItem == 'statistics' ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
      <span class="icon icon-product-coverage AC_C"></span>
      <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Statistics' | translate }}</span>
    </a>
  </li>

  <li *ngIf="menuItemAccess.activityMailing" [title]="'MENUITEMS.Activity & Mailing' | translate"
    class="menuListItem blackColor" (click)="onActivityMailingClick()" appHoverClass
    [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor"
    [ngStyle]="{ 'background-color': themeTemplate.themeColor, color: themeTemplate.textColor }">
    <a class="d-blockk" [ngStyle]="{ color: themeTemplate.textColor }">
      <span class="icon icon-Statistic AC_C"></span>
      <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Activity & Mailing' | translate }}</span>
      <span class=" arrowSize float-end" [ngStyle]="{ transform: isStatisticsOpen ? 'rotate(90deg)' : 'rotate(0deg)'}">
        <span class="icon icon-right-hollow-arrow"></span>
      </span>
    </a>
  </li>
  <ul [@slideInOutStatistics]="animationStatisticsState">
    <li *ngIf="menuItemAccess.campaigningStats" [title]="'Activity & Mailing/Campaigns'"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.activityMailingCampaigns ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor }">
      <a routerLink="{{'/'+menuItemUrlsConstants.activityMailingCampaigns}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.activityMailingCampaigns ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-Campaigning-stats AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Campaign' | translate }}</span>
      </a>
    </li>

    <li *ngIf="menuItemAccess.mailingStats" [title]="'Activity & Mailing/mailing'"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem"
      [ngStyle]="{ 'background-color': selectedItem == menuItemUrlsConstants.activityMailing ? themeTemplate.secondaryColor : themeTemplate.themeColor,      color: themeTemplate.textColor    }">
      <a routerLink="{{'/'+menuItemUrlsConstants.activityMailing}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.activityMailing ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-Mailing-stats AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Mailing' | translate }}</span>
      </a>
    </li>
    <li *ngIf="menuItemAccess.voucher_campaigningStats" [title]="'Activity & Mailing/Voucher-Campaigns'"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
      'background-color': selectedItem == menuItemUrlsConstants.activityMailingVoucherCampaigns ? themeTemplate.secondaryColor : themeTemplate.themeColor,
      color: themeTemplate.textColor }">
      <a routerLink="{{'/'+menuItemUrlsConstants.activityMailingVoucherCampaigns}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.activityMailingVoucherCampaigns ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-Campaigning-stats AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Voucher-Campaigns' | translate }}</span>
      </a>
    </li>
    <li *ngIf="menuItemAccess.moderationVerificationStats" [title]="'Activity & Mailing/moderation-verification-stats'"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
      'background-color': selectedItem == menuItemUrlsConstants.activityMailingModerationVerification ? themeTemplate.secondaryColor : themeTemplate.themeColor,
      color: themeTemplate.textColor }">
      <a routerLink="{{'/'+menuItemUrlsConstants.activityMailingModerationVerification}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.activityMailingModerationVerification ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-moderation-stats AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Moderation & Verification' | translate }}</span>
      </a>
    </li>
    <li *ngIf="menuItemAccess.reportingStats" [title]="'Activity & Mailing/userReporting'"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
      'background-color': selectedItem == menuItemUrlsConstants.activityMailingUserReporting ? themeTemplate.secondaryColor : themeTemplate.themeColor,
      color: themeTemplate.textColor }">
      <a routerLink="{{'/'+menuItemUrlsConstants.activityMailingUserReporting}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.activityMailingUserReporting ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor }">
        <span class="icon icon-product-coverage AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.User Statistics Report' | translate }}</span>
      </a>
    </li>

    <li *ngIf="isMSMUser" title="Activity & Mailing/userReporting" class="menuListItem blackColor subMenuItem"
      appHoverClass [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor"
      [selectedItem]="selectedItem" [ngStyle]="{ 'background-color': selectedItem == menuItemUrlsConstants.activityMailingModerationStatistics ? themeTemplate.secondaryColor : themeTemplate.themeColor,
      color: themeTemplate.textColor }">
      <a routerLink="{{'/'+menuItemUrlsConstants.activityMailingModerationStatistics }}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.activityMailingModerationStatistics ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor }">
        <span class="icon icon-product-coverage AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Moderation Statistics' | translate }}</span>
      </a>
    </li>

  </ul>

  <li *ngIf="menuItemAccess.reportScheduling" [title]="'MENUITEMS.Report Scheduling' | translate" appHoverClass
    [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem"
    [ngStyle]="{ 'background-color': selectedItem == 'reportScheduling' ? themeTemplate.secondaryColor : themeTemplate.themeColor, 
      color: themeTemplate.textColor }" class="menuListItem blackColor">
    <a routerLink="/reportScheduling" (click)="onMenuItemClick()"
      [ngStyle]="{'background-color': selectedItem == 'reportScheduling' ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
      <span class="icon icon-report AC_C"></span>
      <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Report Scheduling' | translate }}</span>
    </a>
  </li>

  <li *ngIf="menuItemAccess.globalComparison" [title]="'MENUITEMS.Global Comparison' | translate" appHoverClass
    [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem"
    [ngStyle]="{
      'background-color': selectedItem == 'globalComparison' ? themeTemplate.secondaryColor : themeTemplate.themeColor,
      color: themeTemplate.textColor
    }" class="menuListItem blackColor">
    <a routerLink="/globalComparison" (click)="onMenuItemClick()"
      [ngStyle]="{'background-color': selectedItem == 'globalComparison' ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
      <span class="icon icon-compare-market AC_C"></span>
      <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Global Comparison' | translate }}</span>
    </a>
  </li>

  <li *ngIf="menuItemAccess.platformStatus" [title]="'MENUITEMS.Platform Status' | translate" appHoverClass
    [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem"
    [ngStyle]="{
      'background-color': selectedItem == 'platformStatus' ? themeTemplate.secondaryColor : themeTemplate.themeColor,
      color: themeTemplate.textColor
    }" class="menuListItem blackColor">
    <a routerLink="/platformStatus" (click)="onMenuItemClick()"
      [ngStyle]="{'background-color': selectedItem == 'platformStatus' ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
      <span class="icon icon-admin-overview AC_C"></span>
      <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Platform Status' | translate }}</span>
    </a>
  </li>

  <li *ngIf="menuItemAccess.settings" [title]="'MENUITEMS.Settings' | translate" class="menuListItem blackColor"
    (click)="openSettingsMenu()" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
    [themeColor]="themeTemplate.themeColor"
    [ngStyle]="{ 'background-color': themeTemplate.themeColor, color: themeTemplate.textColor }">
    <a class="d-blockk" [ngStyle]="{ color: themeTemplate.textColor }">
      <span class="icon icon-Setting AC_C"></span>
      <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Settings' | translate }}</span>
      <span class=" arrowSize float-end" [ngStyle]="{ transform: settingsOpen ? 'rotate(90deg)' : 'rotate(0deg)'}">
        <span class="icon icon-right-hollow-arrow"></span>
      </span>
    </a>
  </li>
  <ul [@slideInOut]="settingsAnimationState">
    <li *ngIf="menuItemAccess.voucherCampaigns" [title]="'MENUITEMS.Voucher Campaigns' | translate"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem"
      [ngStyle]="{ 'background-color': selectedItem == menuItemUrlsConstants.settingsVoucherCampaigns ? themeTemplate.secondaryColor : themeTemplate.themeColor, color: themeTemplate.textColor}">
      <a routerLink="{{'/'+menuItemUrlsConstants.settingsVoucherCampaigns}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.settingsVoucherCampaigns ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-campaign AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Voucher Campaigns' | translate }}</span>
      </a>
    </li>
    <li *ngIf="menuItemAccess.manageAccounts" [title]="'MENUITEMS.Manage Accounts' | translate"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.settingsManageAccount ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.settingsManageAccount}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.settingsManageAccount ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-account AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Manage Accounts' | translate }}</span></a>
    </li>
    <li *ngIf="menuItemAccess.automatedBadging" class="menuListItem blackColor subMenuItem"
      [title]="'MENUITEMS.Automated Badging' | translate" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.settingsAutomatedBadging ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.settingsAutomatedBadging}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.settingsAutomatedBadging ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-badge AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Automated Badging' | translate }}</span>
      </a>
    </li>
    <li *ngIf="menuItemAccess.pieSetup" [title]="'MENUITEMS.PIE Setup' | translate"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.settingsPieSetup ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.settingsPieSetup}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.settingsPieSetup ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-pie-setup AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.PIE Setup' | translate }}</span>
      </a>
    </li>
    <li *ngIf="menuItemAccess.autoModeration" [title]="'MENUITEMS.Auto Moderation' | translate"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
      'background-color': selectedItem == menuItemUrlsConstants.settingsAutoModeration ? themeTemplate.secondaryColor : themeTemplate.themeColor,
      color: themeTemplate.textColor
    }">
      <a routerLink="{{'/'+menuItemUrlsConstants.settingsAutoModeration}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.settingsAutoModeration ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-pie-setup AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Auto Moderation' | translate }}</span>
      </a>
    </li>
    <li *ngIf="menuItemAccess.manageMarkets" [title]="'MENUITEMS.Manage Markets' | translate"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.settingsManageMarket ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.settingsManageMarket}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.settingsManageMarket ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-market AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Manage Markets' | translate }}</span>
      </a>
    </li>
    <li *ngIf="menuItemAccess.manageRetailers" [title]="'MENUITEMS.Manage Retailers' | translate"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.settingsManageRetailers ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.settingsManageRetailers}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.settingsManageRetailers ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-market AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Manage Retailers' | translate }}</span>
      </a>
    </li>

    <li *ngIf="menuItemAccess.managePermissions" [title]="'MENUITEMS.Manage Permissions' | translate"
      class="menuListItem blackColor subMenuItem" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [selectedItem]="selectedItem" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.settingsManagePermissions ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.settingsManagePermissions}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.settingsManagePermissions ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}">
        <span class="icon icon-manage-permissions AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Manage Permissions' | translate }}</span>
      </a>
    </li>

    <li *ngIf="menuItemAccess.changeTheme" class="menuListItem blackColor subMenuItem"
      [title]="'MENUITEMS.Change Theme' | translate" (click)="onChangeTheme(changeThemeModalRef)" appHoverClass
      [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor"
      [ngStyle]="{ 'background-color': themeTemplate.themeColor, color: themeTemplate.textColor }">
      <a [ngStyle]="{ color: themeTemplate.textColor }" (click)="onMenuItemClick()">
        <span class="icon icon-theme AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Change Theme' | translate }}</span></a>
    </li>
    <!-- Review Upload option -->
    <li *ngIf="menuItemAccess.reviewUpload" class="menuListItem blackColor subMenuItem"
      [title]="'MENUITEMS.Review Upload' | translate" (click)="openReviewUploadWindow(reviewUploadModalRef)"
      appHoverClass [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor"
      [ngStyle]="{ 'background-color': themeTemplate.themeColor, color: themeTemplate.textColor }">
      <a [ngStyle]="{ color: themeTemplate.textColor }" (click)="onMenuItemClick()">
        <span class="icon icon-upload AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Review Upload' | translate }}</span></a>
    </li>

    <li *ngIf="menuItemAccess.badgeUpload" class="menuListItem blackColor subMenuItem"
      [title]="'MENUITEMS.Badge Update' | translate" (click)="openBadgeUploadWindow(badgeUpdateModalRef)" appHoverClass
      [hoverColor]="themeTemplate.secondaryColor" [themeColor]="themeTemplate.themeColor"
      [ngStyle]="{ 'background-color': themeTemplate.themeColor, color: themeTemplate.textColor }">
      <a [ngStyle]="{ color: themeTemplate.textColor }" (click)="onMenuItemClick()">
        <span class="icon icon-upload AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Badge Update' | translate }} </span>
      </a>
    </li>

    <li *ngIf="menuItemAccess.isMultiLanguageSetupAccess" class="menuListItem blackColor subMenuItem"
      [title]="'MENUITEMS.MultiLanguage Setup' | translate" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.settingsMultiLanguageSetup ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.settingsMultiLanguageSetup}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.settingsMultiLanguageSetup ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}"><span
          class="icon icon-manage-permissions AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.MultiLanguage Setup' | translate }}</span>
      </a>
    </li>

    <li *ngIf="menuItemAccess.isManageSubscription" class="menuListItem blackColor subMenuItem"
      [title]="'MENUITEMS.Manage Subscription' | translate" appHoverClass [hoverColor]="themeTemplate.secondaryColor"
      [themeColor]="themeTemplate.themeColor" [ngStyle]="{
        'background-color': selectedItem == menuItemUrlsConstants.settingsManageSubscription ? themeTemplate.secondaryColor : themeTemplate.themeColor,
        color: themeTemplate.textColor
      }">
      <a routerLink="{{'/'+menuItemUrlsConstants.settingsManageSubscription}}" (click)="onMenuItemClick()"
        [ngStyle]="{'background-color': selectedItem == menuItemUrlsConstants.settingsManageSubscription ? themeTemplate.secondaryColor : themeTemplate.themeColor, 'color': themeTemplate.textColor}"><span
          class="icon icon-manage-permissions AC_C"></span>
        <span class="menu-item-text overflow-ellipse-wrap">{{ 'MENUITEMS.Manage Subscription' | translate }}</span>
      </a>
    </li>
  </ul>
</ul>


<div class="modal fade" bsModal #changeThemeModalRef="bs-modal" tabindex="-1" role="dialog">
  <app-change-theme [ready2LoadWindow]="loadChangeThemeModal"
    (closeThemeWindow)="onCloseChangeTheme()"></app-change-theme>
</div>

<div class="modal fade" bsModal #badgeUpdateModalRef="bs-modal" tabindex="-1" role="dialog">
  <app-badge-update [ready2LoadWindow]="loadBadgeModal"></app-badge-update>
</div>

<div class="modal fade" bsModal #reviewUploadModalRef="bs-modal" tabindex="-1" role="dialog">
  <app-review-upload [ready2LoadWindow]="loadReviewUploadModal"></app-review-upload>
</div>