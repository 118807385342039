<div *ngIf="visibleManageClientWindow" class="modalContainer overlayWrapper PF style-4">
    <div class="editProfileDetailView">
        <div class="row header-backcolor"
            [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
            <div class="col-md-offset-1 col-md-9 headerName">
                {{ popupTitle | translate}}
            </div>
            <div class="col-md-offset-1 col-md-1">
                <span class="closeEditProfile pull-right MT-12" (click)="onCloseUpdateWindow()">
                    <span class="iconContainerSpan spanContainer closeBtnContainer">
                        <span class="icon icon-cancel"></span>
                    </span>
                </span>
            </div>
        </div>

        <div class="row containerHeader" [ngStyle]="{'background-color': 'white', color: applyThemeService.$textColor}">
            <div class="vertical-space"></div>
            <form #addNewClientForm="ngForm" (ngSubmit)="onSubmitClientClick(addNewClientForm)" novalidate>
                <div class="row">
                    <div class="col-md-3 filterlabel PL-0">
                        {{ "Company Name" | translate }} <sup class="redColor"> *</sup>:
                    </div>
                    <div class="col-md-8">
                        <input class="W-90P boxShadow blackColor" type="text" name="companyName"
                            placeholder="{{'Enter'|translate}} {{'Company' | translate}} {{'Name' | translate}}" ngModel
                            #companyName="ngModel" [(ngModel)]="clientModel.companyName"
                            [class.disabled]="!isAddClientWindow" [disabled]="!isAddClientWindow"
                            (input)="allowOnlySpace(companyName)" />
                        <ng-container *ngIf="companyName.invalid">
                            <div class="text-danger validation-errormsg">
                                {{ companyName.errors?.['error'] }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-md-1"></div>
                </div>
                <div class="row">
                    <div class="col-md-3 filterlabel PL-0">
                        {{ "Sender Email" | translate }} <sup class="redColor"> *</sup>:
                    </div>
                    <div class="col-md-8">
                        <input class="W-90P boxShadow blackColor" type="email" name="senderEmail" ngModel
                            #senderEmail="ngModel" [(ngModel)]="clientModel.senderEmail"
                            placeholder="{{'Enter'|translate}} {{ 'Sender' | translate }} {{'Email' | translate }}"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                        <div class="text-danger validation-errormsg"
                            *ngIf="senderEmail.errors?.['touched'] || senderEmail.errors?.['dirty'] || senderEmail.errors?.['pattern'] || senderEmail.errors?.['invalid']">
                            <strong>{{"Error"|translate}}</strong>{{ "ERRORS.Please provide a valid email address" | translate }}
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>

                <div class="row">
                    <div class="col-md-1"></div>
                    <div class="cls col-md-9 ML-32" [class.clsbranddetails]="clientModel.brand.length > 0">
                        <div class="row">
                            <div class="col-md-3 filterlabel PL-7 TA_L">
                                {{ "Brand Details :" | translate }}
                            </div>
                            <div class="col-md-8">
                                <div *ngIf="clientModel.brand.length == 0" class="col-md-1 PL-0 ML-20M">
                                    <button type="button" class="cursorPointer transparentButton ML-0"
                                        [disabled]="!(clientModel.companyName && clientModel.senderEmail)"
                                        [class.disabled]="!(clientModel.companyName && clientModel.senderEmail)"
                                        [title]="(clientModel.companyName && clientModel.senderEmail) ? '': ('Please enter required fields first.' | translate)"
                                        (click)="onAddNewBrand()">
                                        <label class="selectaLabel">
                                            <strong>+</strong>
                                        </label>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div *ngFor="let brand of clientModel.brand; let bIndex = index" class="row">
                            <div class="col-md-2 PL-0"></div>
                            <div class="col-md-10 PL-0">
                                <div class="col-md-4">
                                    <input class="col-md-12 boxShadow H-35 blackColor" type="text"
                                        placeholder="{{'Brand' | translate}} {{'Name' | translate}} *"
                                        name="brandName_{{bIndex}}" ngModel #brandName="ngModel"
                                        [(ngModel)]="brand.brandName"
                                        [class.disabled]="!isAddClientWindow && bIndex < existingBrandsCount"
                                        [disabled]="!isAddClientWindow && bIndex < existingBrandsCount"
                                        (input)="checkUniqueInput(brandName, bIndex)" required />
                                    <span *ngIf="brandName.invalid" class="text-danger brandvalidation-errormsg ML-30M">
                                        {{ brandName.errors?.['error'] }}
                                    </span>
                                </div>
                                <div class="col-md-3">
                                    <input class="col-md-12 boxShadow H-35 blackColor" type="text"
                                        placeholder="{{'Brand' | translate}} {{'Short' | translate}} *"
                                        name="brandShort_{{bIndex}}" ngModel #brandShort="ngModel"
                                        [(ngModel)]="brand.brandShort"
                                        [class.disabled]="!isAddClientWindow && bIndex < existingBrandsCount"
                                        [disabled]="!isAddClientWindow && bIndex < existingBrandsCount"
                                        (input)="checkUniqueInput(brandShort, bIndex, 'brandShort')" required/>
                                    <span *ngIf="brandShort.invalid"
                                        class="text-danger brandvalidation-errormsg ML-30M">
                                        {{ brandShort.errors?.['error'] }}
                                    </span>
                                </div>
                                <div class="col-md-3">
                                    <input class="col-md-12 boxShadow H-35 blackColor"
                                        placeholder="{{'Brand' | translate}} {{'Code' | translate}} *" type="text"
                                        name="brandCode_{{bIndex}}" ngModel #brandCode="ngModel"
                                        [(ngModel)]="brand.brandCode" validateNumber="4"
                                        [class.disabled]="!isAddClientWindow && bIndex < existingBrandsCount"
                                        [disabled]="!isAddClientWindow && bIndex < existingBrandsCount"
                                        (input)="checkUniqueInput(brandCode, bIndex, 'brandCode')" required />
                                    <span *ngIf="brandCode.invalid" class="text-danger brandvalidation-errormsg ML-30M">
                                        {{ brandCode.errors?.['error'] }}
                                    </span>
                                </div>
                                <div class="col-md-2 TA_C MT-5M">
                                    <button type="button" class="cursorPointer transparentButton"
                                        [class.disabled]="(clientModel.brand.length-1 !== bIndex || addNewClientForm.invalid)"
                                        [disabled]="(clientModel.brand.length-1 !== bIndex || addNewClientForm.invalid)"
                                        (click)="onAddNewBrand()">
                                        <label class="selectaLabel">
                                            <strong>+</strong>
                                        </label>
                                    </button>
                                    <button type="button" class="cursorPointer transparentButton"
                                        (click)="onRemoveBrand(bIndex)"
                                        [class.disabled]="!isAddClientWindow && bIndex < existingBrandsCount"
                                        [disabled]="!isAddClientWindow && bIndex < existingBrandsCount">
                                        <label class="selectaLabel">
                                            <strong>-</strong>
                                        </label>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>

                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-7">
                        <button *ngIf="accessControls.isCreate" class="btn btn-primary customBtn ML-40" [ngStyle]="{ 'background-color': applyThemeService.$themeColor, 'border-color': applyThemeService.$themeColor,
                            color: applyThemeService.$textColor}" [class.disabled]="addNewClientForm.invalid"
                            [disabled]="addNewClientForm.invalid">
                            {{"BUTTONS.Submit" |translate }}
                        </button>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </form>
        </div>

    </div>
</div>